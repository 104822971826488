import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLazyQuery, useQuery} from "@apollo/react-hooks";

import useGlobal from "../../store";

import {path} from "../../utils/api/apiQueries";

import Card from "../../components/Card";
import {useHistory} from "react-router";
import parseUrl from "../../utils/parseUrl";

const TicketsList = () => {

        const [{
            userInfo: {companyId},
            tickets,
        },
            globalActions
        ] = useGlobal();
        const {setTicketsBySupervisor, setTicketContent} = globalActions;

        const {push, goBack} = useHistory();

        const {data} = useQuery(path.query.getTicketsByUser, {
            variables: {
                assignee: parseUrl(window.location.href),
                status: 'review',
                company: companyId
            },
            fetchPolicy: 'no-cache'
        });

        const parseTickets = useCallback(tickets => {
            return tickets.allTickets.edges
                .map(item => item.node)
                .map(item => {
                    const {ticketContents, ...rest} = item;
                    return {
                        ...rest,
                        ticketImage: ticketContents.edges.length ? ticketContents.edges[0].node.urlPhoto : null
                    }
                });
        }, []);

        const [getTicketById, {data: ticketContents}] = useLazyQuery(path.query.getTicketById);

        const selectTicket = useCallback(id => {
            getTicketById({
                variables: {
                    id
                }
            })
        }, []);

        useEffect(() => {
            if (!ticketContents) return;
            setTicketContent(ticketContents.ticket);
            push({
                pathname: './ticket',
                search: ticketContents.ticket.id
            });
        }, [ticketContents]);


        useEffect(() => {
            if (!data) return;
            setTicketsBySupervisor(parseTickets(data));
        }, [data, setTicketsBySupervisor]);

        const ticketsCards = useMemo(() => {
            return tickets.map(item => {
                const {createdAt, id, ticketImage, title} = item;
                const data = {
                    id,
                    image: null,
                    background: ticketImage,
                    text: {
                        title,
                        createdAt,
                    }
                };
                return <Card
                    key={item.id}
                    data={data}
                    selectCard={selectTicket}
                />
            })
        }, [tickets, data]);

        return (
            <div className='supervisor-content-wrapper'>
                {ticketsCards}
            </div>
        );
    }
;
export default TicketsList;
