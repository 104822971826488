import React from "react";

const SubMenuItem = ({action, title, value, resScale}) => {
    return <div
        className={resScale === value ?'toggled-button' : ''}
        style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 5,
            cursor: 'pointer',
            transition: '.2s'
        }}
        onClick={() => action(value)}>{title}</div>;
};
export default SubMenuItem;