import {useEffect, useState} from 'react';
import useGlobal from "../store";

const useSignUpForm = initialValues => {
    const [{userInfo}, {showMessage}] = useGlobal();

    const [inputs, setInputs] = useState(initialValues);
    // const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

    //TODO: CREATE MORE ACCURATE VALIDATOR OR TO USE VALIDATOR LIB

    let storageKey='companySearchAnnotation_'+userInfo.companyId;

    const inputValidator = (name, value) => {
        const floatRegExp = new RegExp('^\\d{1,6}(\\.\\d{0,2})?$');
        const intRegExp = /^\d*$/;
        switch (name) {
            case ('price'): {
                return value.match(floatRegExp);
            }
            case ('salePrice'): {
                return value.match(floatRegExp);
            }
            case ('quantity'): {
                return value.match(intRegExp);
            }
            default:
                return value
        }
    };

    const handleInputChange = e => {
        // e.persist();
        const {name, value} = e.target;
        const bodyMessage = name === "quantity" ? 'Цілі числа': 'Цілі або дробові числа розділені знаком "."'

        if (inputValidator(name, value) || value === '')
            setInputs(inputs => ({...inputs, [name]: {...inputs[name], value}}));
        else {
            showMessage({
                type: 'warning',
                body: bodyMessage
            });
        }
    };

    const resetInputs = () => setInputs(() => {
        let resetedObj = {};
        Object.keys(initialValues).forEach(inputName => resetedObj[inputName] = '');
        return resetedObj;
    });

    const handleClick = e => {
        if (!inputs['objectName']){
            let value=localStorage.getItem(storageKey);
            setInputs(inputs => ({objectName:value}));
        }
    }


    return {
        inputs,
        resetInputs,
        handleInputChange,
        handleClick
    };
};

export default useSignUpForm;
