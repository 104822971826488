import React from "react";

export const infoPageData = {
    menuText: [
        {
            text: 'Як стати',
            ref: 'howToRef'
        },
        {
            text: 'Переваги',
            ref: 'prosRef'
        },
        {
            text: 'Відгуки',
            ref: 'reviewsRef',
        },
        {
            text: 'Акції',
            ref: 'specialsRef'
        },
    ],
    siteCover: {
        heading: 'Стань оператором з розмітки фото та заробляй гроші не виходячи з дому',
        subHeading: 'Для того щоб стати оператором, заповни форму реєстрації',
        formTitle: 'Реєструйся та починай заробляти менш ніж за добу!'
    },
    howTo: [
        {
            icon: 'faEdit',
            text: 'Заповни форму реєстрації',
        },
        {
            icon: 'faPhoneSquareAlt',
            text: 'Отримай листа з інструкцією',
        },
        {
            icon: 'faEnvelopeSquare',
            text: 'Ознайомся з інформацією про роботу',
        },
        {
            icon: 'faSignInAlt',
            text: 'Отримай логін та пароль доступу до системи',
        },
        {
            icon: 'faMoneyBillWaveAlt',
            text: 'Працюй з дому у вільний час та отримай винагороду',
        },
    ],
    features: [
        {
            icon: 'faCreditCard',
            text: 'Впливай сам на свій заробіток'
        },
        {
            icon: 'faPlanetMoon',
            text: 'Працюй де завгодно'
        },
        {
            icon: 'faCalendarCheck',
            text: 'Працюй коли зручно'
        },
        {
            icon: 'faClock',
            text: 'Щотижнева виплата'
        },
        {
            icon: 'faHeadset',
            text: 'Технічна підтримка'
        },
        // {
        //     icon: 'faUserFriends',
        //     text: 'Дружній колектив'
        // },
    ],
    requirements: [
        {
            text: 'Вік від 16 років',
        },
        {
            text: 'Комп\'ютер або ноутбук',
        },
        {
            text: 'Інтернет підключення'
        },
        {
            text: 'Бажання працювати :)'
        }
    ],
    reviews: [
        {
            title: 'Таня - Київ',
            subtitle: 'Я працюю з серпня 2019 року.',
            text: 'Плюсів дуже багато, мені не потрібно витрачати час на дорогу в офіс, за цей час я маю можливість більше працювати щоб отримати хорошу, вчасну виплату на свою банківську карту, в компанії дружній колектив, завдяки керівнику який завжди допомагає в роботі.',
            imgUrl: 'user1'
        },
        {
            title: 'Жанна - Путивль',
            subtitle: 'Я працюю з травня 2019 року.',
            text: 'Великий плюс в роботі,що не потрібно мати якихось особливих знань . Можна швидко навчитися і працювати із задоволенням. Виплати проходять кожного тижня, і завжди вчасно. Робота дуже підходить студентам,так як працювати можна,коли є вільний час.',
            imgUrl: 'user3'
        },
        {
            title: 'Інна – Львів',
            subtitle: 'Я працюю майже 2 роки.',
            text: 'З самого початку я не збиралася так довго тут затриматися, але потім все змінилося. Я дуже звикла до своєї команди, і вже не уявляю себе у роботі з кимось іншим. Плюсом для мене ще є те, що заробітня плата кожен тиждень, та не обов\'язково цілий день проводити в офісі бо можна працювати віддалено у себе в дома. Я не знаю який час я ще пропрацюю в цій компанії, але одне можу сказати точно, що я тут на довго.',
            imgUrl: 'user2'
        },
        {
            title: 'Іван - Глухів',
            subtitle: 'Я працюю з липня 2019 року.',
            text: 'В нашій роботі є багато плюсів. Основним із них є наш дружній колектив. Тут тобі завжди допоможуть та дадуть пораду. Наступним не мало важним плюсом — є сайт для роботи. Він максимально оптимізований для зручної роботи як оператора, так і для перевірки тікетів супервайзерами. Є ще багато гарного в нашій роботі, але саме ці 2 складові — роблять її унікальною.',
            imgUrl: 'user4'
        },
    ]
};