import {useHistory} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import React, {useEffect, useMemo, useState} from 'react';


import useGlobal from "../../store";

import Card from '../../components/Card';

import {path} from "../../utils/api/apiQueries";


const SupervisorContent = () => {
    const [{
        userInfo: {id: userId, companyId},
    }, {setSelectedOperator, setSupervisorTaken}] = useGlobal();

    const {push} = useHistory();

    const [operators, setOperators] = useState([]);

    const {data} = useQuery(path.query.getOperators, {
        variables: {
            company: companyId
        },
        fetchPolicy: 'no-cache'
    });

    const selectOperator = id => {
        setSelectedOperator(id);
        push({
            pathname: './main/tickets-list',
            search: id
        });
    };

    useEffect(() => {
        if (!data || !data.usersRelatedToSupervisor) return;
        const operators = data.usersRelatedToSupervisor.edges.map(item => item.node);
        setOperators(operators);
    }, [data]);

    const operatorsCards = useMemo(() => {
        return operators.map(item => {
            const {id, avatar, firstName, lastName, fathersName, ticketsInReviewCounter} = item;
            const data = {
                id,
                image: avatar,
                background: null,
                ticketCount: ticketsInReviewCounter,
                text: {
                    firstName,
                    lastName,
                    fathersName
                }
            };
            return <Card
                key={id}
                data={data}
                selectCard={selectOperator}
            />
        });
    }, [operators, selectOperator]);

    useEffect(() => {
        if (operators.length && operators.some(operator => operator.id === userId)) {
            setSupervisorTaken(true);
            return;
        }
        setSupervisorTaken(false)
    }, [operators, setSupervisorTaken]);

    return (
        <div className='supervisor-content-wrapper'>
            {operatorsCards}
        </div>
    );
};
export default SupervisorContent;
