import {Redirect, useHistory} from "react-router";
import React, {useEffect, useMemo} from 'react';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";

import useGlobal from "../../store";
import {path} from "../../utils/api/apiQueries";

export default (OriginalComponent) => {
    return props => {
        const [
            {
                isAuthorized,
                userInfo: {isInitial}
            },
            {setAuthToken, setUserData, setActiveCompany, showMessage, logout, resetTicket, resetGeneral}
        ] = useGlobal();

        const history = useHistory();

        const [requestViewer, {error, data}] = useLazyQuery(path.query.getViewer);
        const [getRefreshToken, {data: newToken, error: refreshError}] = useMutation(path.mutation.refreshToken);

        const cookiesToken = useMemo(() => {
            const tokenAuth = JSON.parse(localStorage.getItem('tokenAuth'));
            if (tokenAuth) {
                return tokenAuth
            }
            return null;
        }, [isInitial, isAuthorized]);


        useEffect(() => {
            if (!cookiesToken) return;
            if (isInitial) {
                const {token} = cookiesToken;
                requestViewer({
                    variables: {
                        token
                    },
                });
            }
        }, [isInitial, cookiesToken]);


        useEffect(() => {
            if (data && !error) {
                setUserData(data.viewer);
            }
        }, [data, error, setUserData, requestViewer]);


        useEffect(() => {
            if (!cookiesToken) return;
            if (error) {
                const {refreshToken} = cookiesToken;
                getRefreshToken({
                    variables: {
                        refreshToken
                    }
                })
            }
        }, [error, cookiesToken]);


        useEffect(() => {
            if (newToken) {
                setAuthToken(newToken.refreshToken.token);
                localStorage.setItem('tokenAuth', JSON.stringify(newToken.refreshToken, null, 2));
            } else if (refreshError) {
                localStorage.clear();
                logout();
                resetGeneral();
                resetTicket();
                history.push('./login');
            }
        }, [newToken, refreshError, history, logout, resetTicket, setAuthToken, resetGeneral]);


        useEffect(() => {
            const activeCompany = JSON.parse(localStorage.getItem('activeCompany'));
            if (!activeCompany) {

                return;
            }
            setActiveCompany(activeCompany);
        }, [setActiveCompany, showMessage]);

        if (cookiesToken && isInitial) {
            return <div className='loader-wrapper'>
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        }

        return isAuthorized
            ? <OriginalComponent {...props} />
            : <Redirect to="/login"/>
    };
};
