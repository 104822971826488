import React, {memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { useLazyQuery } from "@apollo/react-hooks";
import ReloadIcon from "../../../assets/images/reload-icon.svg";

import {path} from "../../../utils/api/apiQueries";
import useGlobal from "../../../store";
import useColorMode from "../../../hooks/useColorMode";

const compWrapClass = classNames('container', 'general-info-wrapper');

const GeneralInfo = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const {setGeneralStats} = globalActions;
    const {gradients, activeGradient} = globalState;

    const {
        generalStats,
        userInfo: {companyId}
    } = globalState;

    const [fetchGeneralInfo, { loading, data }] = useLazyQuery(path.query.getGeneralInfo, {
      variables: { companyId },
      fetchPolicy: "network-only",
      skip: !companyId,
    });

    useEffect(() => {
        fetchGeneralInfo();
    }, []);
    
    useEffect(() => {
        if (data) {
            // console.log("PSt, GOT SOME GENERAL INFO FOR YOU!")
            setGeneralStats(JSON.parse(data.generalInfo));
        }
    }, [data, setGeneralStats]);

      const HandleUpdateInfo = () => {
        fetchGeneralInfo();
        setIsLoadingData(true);

        setTimeout(() => {
          setIsLoadingData(false);
        }, 1000);
      };

    const statsArr = useMemo(() => {
        return Object.keys(generalStats).map((item) => {
            return (
                <div
                    key={item}
                    className='single-stat-wrapper'
                    style={{
                        background: gradients[activeGradient].secondaryContainer,
                    }}
                >
                    <div className='single-stat'>
                        <h1>{item}</h1>
                        <h2>{generalStats[item]}</h2>
                    </div>
                </div>
            )
        })
    }, [generalStats, activeGradient, gradients]);


    const styles = useColorMode();

    return (
      <div
        className={compWrapClass}
        style={{
          ...styles,
          boxShadow: `0 0 24px -9px ${gradients[activeGradient].accentColor}`,
        }}
      >
        <h1>Загальна інфо</h1>
        <div className={`reload-data-user ${isLoadingData ? "loading" : ""}`} onClick={HandleUpdateInfo}>
          <img src={ReloadIcon} alt='Reload icon' />
        </div>
        <div className='general-info-wrapper-container'>{statsArr.length > 0 && statsArr}</div>
      </div>
    );
};
export default memo(GeneralInfo);