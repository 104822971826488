import React from "react";
import * as Sentry from "@sentry/browser";

Sentry.init({dsn: "https://f5858a070de0467597dfbb10eccdadc4@sentry.io/2070697"});


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            info: null,
            eventId: null
        };
    }

    static getDerivedStateFromError = () => {
        return {hasError: true};
    };

    componentDidCatch = (error, info) => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.withScope((scope) => {
                scope.setExtras(info);
                const eventId = Sentry.captureException(error);
                this.setState({eventId});
            });
        }

        this.setState({error, info});
    };


    render() {

        if (this.state.hasError) {
            return (
                <div className='error-fallback'>
                    <div className='error-fallback-container'>
                        <h1>Ой, щось пішло не так, <br/> перепрошуємо за незручності :(</h1>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;