import classes from 'classnames';
import React, {useEffect} from 'react';
import {useHistory} from "react-router";
import isEmail from 'validator/lib/isEmail'
import {useMutation} from "@apollo/react-hooks";
import {Link} from "react-router-dom";

import useForm from '../hooks/useForm';

import {path} from "../utils/api/apiQueries";

import useGlobal from "../store";

import {faCatSpace} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const Login = () => {
    const [, {showMessage}] = useGlobal();
    const loginWrapClass = classes('container', 'login-reg-request-wrapper');
    const history = useHistory();

    const {handleInputChange, inputs} = useForm({
        // email: 'clickdev@gmail.com',
        // email: 'supervisor@gmail.com',
        // password: '123123kt',
        email: {
            value: '',
            placeholder: 'email'
        },
        password: {
            value: '',
            placeholder: 'пароль'
        },
    });

    const {email, password} = inputs;

    const [goLogin, {data, error}] = useMutation(path.mutation.login);


    const getLoggedIn = e => {
        e.preventDefault();
        if (!email.value.length || !password.value.length) {
            showMessage({
                type: 'warning',
                body: 'Поля не можуть бути порожніми'
            });
            return;
        }
        if (!isEmail(email.value)) {
            showMessage({
                type: 'warning',
                body: 'Некоректна адреса електронної пошти'
            });
            return;
        }
        if (isEmail(email.value) && password.value)
            goLogin({
                variables: {
                    username: email.value,
                    password: password.value
                }
            })
    };


    useEffect(() => {
        if (data) {
            const {tokenAuth} = data;
            localStorage.setItem('tokenAuth', JSON.stringify(tokenAuth, null, 2));
            history.push('./main')
        } else if (error) {
            showMessage({
                type: 'warning',
                body: 'Введені невірні дані, перевірте та спробуйте ще раз!'
            })
        }

    }, [data, history, error, showMessage]);


    if (JSON.parse(localStorage.getItem('tokenAuth'))) {
        history.push('./main');
        return null;
    }

    return (
        <div className='login-page-wrapper'>
            <div className='login-form-wrapper'>
                <div className='login-form-left'>
                    <h1><span>Вітаємо</span> в Outforz-work</h1>
                    <FontAwesomeIcon icon={faCatSpace} color={'#fff'}/>
                    <p>Дистанційна робота і гнучкий графік. Ви самі вибираєте зручний час для роботи, не витрачаєте
                        щодня час на дорогу і самостійно контролюєте завантаженість роботи. Кілька годин на добу або
                        більше - вибір за вами! Уточнюйте про можливість працювати у свого керівника.</p>
                    <h1>
                        <Link to='/info'>
                            Як стати оператором?
                        </Link>
                    </h1>
                </div>
                <div className={loginWrapClass}>
                    <h1> Авторизація </h1>
                    <form>
                        <input
                            onChange={handleInputChange}
                            type="email"
                            placeholder='пошта'
                            name='email'
                            value={email.value}
                            autoComplete='email'
                        />
                        <input
                            onChange={handleInputChange}
                            type="password"
                            placeholder='пароль'
                            name='password'
                            value={password.value}
                            autoComplete='current-password'
                        />
                        <button
                            type='submit'
                            onClick={getLoggedIn}
                        >
                            Вхід
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
