import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";

import useGlobal from "../store";

import {path} from "../utils/api/apiQueries";

import useHandleUserEvents from "../hooks/useHandleUserEvents";
import useAnnotationManager from "../hooks/useAnnotationsManager";

import formatToBeSent from './drawAnnotation/formatToBeSent';

import SubMenuAnnotation from "../pages/main/photoAnnotation/SubMenuAnnotation";
import ContextAnnotation from "../pages/main/photoAnnotation/ContextAnnotation";
import AnnotationRectangle from "../pages/main/photoAnnotation/AnnotationRectangle";


const DrawAnnotation = ({settings, refScrollTop, activePhoto, setShowObjectTitle, toggledMagnifier}) => {

    const {width: imgWidth, height: imgHeight} = settings;

    const [{userInfo: {companyId, id: _userId, role}}, {showMessage, setPhotoTip, setMousePosition}] = useGlobal();

    const {manager: annotationManager, annotations} = useAnnotationManager();

    const [allObjects, setAllObjects] = useState([]);
    const [toggleContext, setToggleContext] = useState(null);
    const [lastAnnot, setLastAnnot] = useState({
        name: '',
        price: '',
        salePrice: '',
        quantity: ""
    });
    const [persistedObjects, setPersistedObjects] = useState([]);
    const [showAnnotationMenu, setShowAnnotationMenu] = useState(null);
    const [editing, setEditing] = useState(false);

    const [getAllObjects, {data}] = useLazyQuery(path.query.getAllObjects);

    const [resizeMode, setResizeMode] = useState({state:false, annotation: null});

    useEffect(() => {
        annotationManager.hydrate(activePhoto);
        setPhotoTip(activePhoto.tip);
    }, []);

    useEffect(() => {
        if (!companyId) return;
        getAllObjects({
            variables: {
                company: companyId
            },
        });
    }, [companyId, getAllObjects]);

    useEffect(() => {
        if (data) {
            const allObj = data.allObjects.edges.map(item => item.node);
            setAllObjects(allObj);
            setPersistedObjects(allObj);
        }
    }, [data]);


    const [sendAnnotation, {data: _res}] = useMutation(path.mutation.sendAnnotation);


    const handleCancel = id => {
        if (!id) return;
        // handleDelete(id);
        setShowAnnotationMenu(null);
        setToggleContext(null);
        setEditing(false);

        if (!editing)
            annotationManager.delete(id);
    };

    const submitAnnotation = useCallback(annotationInfo => {

        let preparedAnnotationToSend=formatToBeSent(
            annotationInfo,
            annotations,
            _userId,
            activePhoto,
            role);

        sendAnnotation(preparedAnnotationToSend).catch(console.log);
        setShowAnnotationMenu(null);
        if (editing) setEditing(false)
    }, [annotations, activePhoto, editing, resizeMode])

    useEffect(() => {
        if (_res && _res.annotationMutation) {
            const {object: {name}, additionalInfo} = _res.annotationMutation.annotation;
            // console.log(_res.annotationMutation.annotation);
            annotationManager.edit(_res.annotationMutation.annotation, toggleContext);
            setLastAnnot({name, ...JSON.parse(additionalInfo)});
            setToggleContext(null);
        }
    }, [_res]);

    const [deleteAnnotation, {data: result}, error] = useMutation(path.mutation.deleteAnnotation);


    const handleCancelContext=()=>{
        if (toggleContext) {
            setToggleContext(null);
        }
        // setEditing(false);
    }

    const handleDelete = useCallback((id) => {
        if (!editing) {
            deleteAnnotation({
                variables: {
                    id
                }
            }).catch(console.log);
            annotationManager.delete(id);
        }

        if (toggleContext) {
            setToggleContext(null);
        }
        setEditing(false);
    }, [deleteAnnotation, annotationManager, setToggleContext, setEditing]);

    useEffect(() => {
        if (!result) return;
        if (result.deleteAnnotationMutation.ok) {
            showMessage({
                type: 'success',
                body: 'Анотацію успішно видалено'
            });
        } else if (error) {
            showMessage({
                type: 'error',
                body: 'Анотацію не видалено, щось пішло не так!'
            });
        }
    }, [result, error, showMessage]);

    const handleChange = useCallback(() => {
        setEditing(true);
        setShowAnnotationMenu(toggleContext);
    }, [setEditing, toggleContext, setShowAnnotationMenu]);

    const handleSubMenu = useCallback((item, color, position) => {
        if (toggleContext && toggleContext.id === item.id) {
            setToggleContext(null);
        }
        setToggleContext({...item, color, position});
    }, [toggleContext, setToggleContext]);

    const drawStateActions = useMemo(() => {
        return {
            drawActions: {
                setToggleContext,
                setShowAnnotationMenu,
                handleDelete,
                setMousePosition
            },
            drawState: {
                toggleContext,
                showAnnotationMenu,
                annotations
            }
        }
    }, [setToggleContext, setShowAnnotationMenu, toggleContext, showAnnotationMenu, annotations]);

    useEffect(() => {
        if (!activePhoto.valid)
            showMessage({
                type: 'warning',
                body: 'Фото невалідне, анотація недоступна'
            });
    }, [activePhoto]);

    const {
        userEvents: {mouseUp, mouseDown, mouseMove, reset},
        userEventsData: {tempRect}
    } = useHandleUserEvents(settings, refScrollTop, annotationManager, drawStateActions, resizeMode);


    const handleMouseDown = useCallback(e => {
        if (toggleContext) return;
        if (!activePhoto.valid)
            showMessage({
                type: 'warning',
                body: 'Фото невалідне, анотація недоступна'
            });
        if (activePhoto.valid) mouseDown(e);
    }, [activePhoto, showMessage, toggleContext, mouseDown]);


    const handleMouseUp = useCallback(e => {

        setShowObjectTitle(null);

        if (resizeMode.state){

            let currentAnnotation={}
            annotations.forEach(object => {
                if (resizeMode.annotation.id === object.id) {
                    currentAnnotation=object;
                }
            });

            let annotationInfoToSave={
                id:currentAnnotation.id,
                coordinate:{ x1:currentAnnotation.x1, y1:currentAnnotation.y1, x2:currentAnnotation.x2, y2:currentAnnotation.y2},
                name: currentAnnotation.name,
                price: currentAnnotation.price,
                salePrice: currentAnnotation.salePrice,
                quantity: currentAnnotation.quantity,
                objectId:""
            };

            allObjects.forEach(object => {
                if (annotationInfoToSave.name === object.name) {
                    annotationInfoToSave.objectId=object.id;
                }
            })

            submitAnnotation(annotationInfoToSave);
            setResizeMode({state:false})
            reset();

            return;
        }

        if (showAnnotationMenu) return;
        if (e.currentTarget.className !== "draw-overlay") return;
        if (!tempRect.length) return;
        const newAnnotation = mouseUp(e);

        if (newAnnotation.x1 !== newAnnotation.x2) {
            annotationManager.add(newAnnotation)
            setShowAnnotationMenu(newAnnotation);
        }

    }, [
        mouseUp,
        annotationManager,
        setShowAnnotationMenu,
        tempRect,
        showAnnotationMenu,
        toggleContext
    ]);


    const resizeAnnotation=useCallback((item)=>{

        let currentAnnotation=annotations.filter((i)=>{return i.id==item.id});
        if (currentAnnotation && currentAnnotation.length>=1) {
            currentAnnotation=currentAnnotation[0];
            setResizeMode(Object.assign({}, {state: true, annotation: currentAnnotation}));
        }
    }, [annotations, resizeMode])

    const renderRects = useCallback(target => target.map(item => {

            return <AnnotationRectangle
                item={item}
                id={item.id}
                settings={settings}
                refScrollTop={refScrollTop}
                handleDelete={handleDelete}
                toggleContext={toggleContext}
                handleSubMenu={handleSubMenu}
                setShowObjectTitle={setShowObjectTitle}
                showAnnotationMenu={showAnnotationMenu}
                key={`${parseFloat(item.x1)}_${item.id}`}

                resizeAnnotation={resizeAnnotation}
            />
        }
    ), [handleDelete, handleSubMenu, showAnnotationMenu]);


    const allRects = useMemo(() => renderRects(annotations), [annotations, _res, imgWidth, refScrollTop]);
    const tempRectRendered = useMemo(() => renderRects(tempRect), [tempRect]);

    return (
        <>
            <div
                className='draw-overlay'
                style={{
                    width: imgWidth,
                    height: imgHeight,
                    display: toggledMagnifier ? 'none' : 'flex'
                }}
                onMouseUp={handleMouseUp}
                onMouseDown={handleMouseDown}
                onMouseMove={mouseMove}
                onContextMenu={e => e.preventDefault()}
            >
                {allRects}
                {tempRectRendered}

                {(!showAnnotationMenu && toggleContext) &&
                <ContextAnnotation
                    photoToContext={toggleContext}
                    imgSize={settings}
                    scrolledTop={refScrollTop}
                    actions={{
                        handleDelete,
                        handleChange,
                        handleCancelContext
                    }}
                />}

                {showAnnotationMenu &&
                <SubMenuAnnotation
                    allObjects={allObjects}
                    setAllObjects={setAllObjects}
                    persistedObjects={persistedObjects}
                    lastAdded={annotations[annotations.length - 1]}
                    toggleContextItem={toggleContext}
                    imgSize={settings}
                    handleCancel={handleCancel}
                    submitAnnotation={submitAnnotation}
                    showAnnotationMenu={showAnnotationMenu}
                    lastAnnot={lastAnnot}
                    setLastAnnot={setLastAnnot}
                />}
            </div>
        </>
    )
};

export default DrawAnnotation;
