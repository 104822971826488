import gql from 'graphql-tag';

const fragments = {
  ticket: gql(`fragment ticketInfo on TicketNodeConnection {
        edges{
            node{
                id
                title
                createdAt
                additionalInfo
                ticketContents{
                    edges{
                        node{
                            id
                            tip
                            photo
                            valid
                            urlPhoto
                            annotationSet(active: true){
                                edges{
                                    node{
                                        id
                                        coordinate
                                        additionalInfo
                                        object{
                                            name
                                        }
                                    }
                                }
                            }
                            additionalInfo
                        }
                    }
                }
            }
        }
    }`),

  ticketNoContent: gql(`fragment ticketInfo2 on TicketNodeConnection {
        edges{
            node{
                id
                title
                createdAt
                company{
                    id
                }
                ticketContents(first: 1){
                    edges{
                        node{
                            id
                            photo
                            valid
                            urlPhoto
                        }
                    }
                }
            }
        }
    }`),
  // operators: gql(`fragment userSet on UserNodeConnection{
  //                       edges{
  //                         node{
  //                           username
  //                           assigneeTickets(status:"review"){
  //                             edges{
  //                               node{
  //                                 title
  //                                 ticketContents(first: 1){
  //                                   edges{
  //                                     node{
  //                                       urlPhoto
  //                                     }
  //                                   }
  //                                   }
  //                                 }
  //                               }
  //                             }
  //                           }
  //                         }
  //                       }`)
};


export const path = {
  query: {
    getViewer: gql`
      query Viewer($token: String!) {
        viewer(token: $token) {
          id
          role
          username
          firstName
          lastName
          fathersName
          avatar
          assigneeTickets(status: "in_progress") {
            edges {
              node {
                id
                title
                company {
                  id
                  name
                }
              }
            }
          }
          company {
            edges {
              node {
                id
                name
                availableTicketsRemain
              }
            }
          }
        }
      }
    `,
    getCompanyInfo: gql(`query getCompaniesInfo($token: String!){
                              viewer(token: $token){
                                company{
                                  edges{
                                    node{
                                      id
                                      name
                                      availableTicketsRemain
                                    }
                                  }
                                }    
                              }
                            }`),
    getCompanyInfo2: gql(`query getCompaniesInfo2($id: ID!){
            user(id: $id){
                    company{
                      edges{
                        node{
                          id
                          name
                          availableTicketsRemain
                        }
                      }
                    }   
            }
        }`),
    getOperators: gql(`query relatedOperators($company: [ID]) {
            usersRelatedToSupervisor(company: $company){
                edges{
                    node{
                        id
                        firstName
                        lastName
                        fathersName
                        ticketsInReviewCounter(company: $company)
                        avatar
                    }
                }
            }  
        }
        `),

    getAllObjects: gql(`query AllObjects($company: ID!){
            allObjects(company: $company, active: true){
                edges{
                    node{
                        name
                        active
                        id
                        code
                        image
                    }
                }
            }
        }`),

    // getTicketsByUser: gql`query {
    //     allTickets(assignee: "VXNlck5vZGU6NzYwYTkzNTgtOTljYy00ZmQzLTg4ZDEtZmYwZTg5OTFlYmIw",
    //         status: "review",
    //         company_Id: "Q29tcGFueU5vZGU6Mg==") {
    //         edges {
    //             node {
    //                 assignee {
    //                     username
    //                 }
    //                 id
    //                 title
    //                 company {
    //                     name
    //                     id
    //                 }
    //             }
    //         }
    //     }
    // }`,

    getTicketsByUser: gql`
      query getTickets($assignee: ID!, $status: String!, $company: ID) {
        allTickets(assignee: $assignee, status: $status, company: $company) {
          ...ticketInfo2
        }
      }
      ${fragments.ticketNoContent}
    `,

    getGeneralInfo: gql`
      query GeneralInfo($companyId: String!) {
        generalInfo(company: $companyId)
      }
    `,

    getFirstTicket: gql`
      query Ticket($company: ID!, $status: String!) {
        allTickets(company: $company, status: $status) {
          ...ticketInfo
        }
      }
      ${fragments.ticket}
    `,

    statistics: gql(`query{
            statistics{
                payment
                dateJoined
                annotationsDone
                annotationsTotal
                annotationsCorrect
                annotationsSupervised
            }
        }`),

    getTicketById: gql(`query getTicketById($id: ID!){
                          ticket(id: $id){
                            id
                            title
                            createdAt
                            additionalInfo
                            ticketContents{
                              edges{
                                node{
                                  id
                                  tip
                                  urlPhoto
                                  valid
                                  additionalInfo
                                  annotationSet(active: true){
                                    edges{
                                      node{
                                        id
                                        coordinate
                                        object{
                                          name
                                        }
                                        additionalInfo
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                    }
        `),
    getTicketPhotoById: gql(`query ticketContent($id: ID!){
                                    ticketContent(id: $id){
                                        ticket{
                                          title
                                          id
                                          createdAt
                                          additionalInfo
                                        }
                                        id
                                        urlPhoto
                                        valid
                                        tip
                                        additionalInfo
                                        annotationSet(active: true){
                                            edges{
                                                node{
                                                    id
                                                    coordinate
                                                    additionalInfo
                                                    object{
                                                        name
                                                    }
                                                    }
                                                }
                                            }
                                   }
              }
        `),
  },
  mutation: {
    login: gql(`
            mutation tokenAuth($username: String!, $password: String!){
                      tokenAuth(username: $username, password: $password){
                        token
                        refreshToken
                      }
                    }
        `),
    refreshToken: gql(`
            mutation RefreshToken($refreshToken: String!){
                refreshToken(refreshToken: $refreshToken){
                    token
                    refreshToken
                } 
            }
        `),
    revokeToken: gql(`
            mutation RevokeToken($refreshToken: String!){
                revokeToken(refreshToken: $refreshToken){
                    revoked
                }
            }
        `),
    sendAnnotation: gql(`
            mutation annotationMutation($id: ID, $ticketContent: ID!, $createdBy: ID!, $object: ID!, $coordinate: String!, $active: Boolean!, $additionalInfo: String!, $supervised: Boolean){
                annotationMutation(input: {id: $id, ticketContent: $ticketContent, createdBy: $createdBy, object: $object, coordinate: $coordinate, active: $active, additionalInfo: $additionalInfo, supervised: $supervised}){
                    errors{
                    messages
                    }
                    annotation{
                        id
                        object{
                            id
                            name
                        }
                        additionalInfo
                    }
                    
                }
            }
        `),
    finishTicket: gql(`
            mutation closeTicketMutation($id: ID!){
                closeTicketMutation(id: $id){
                    ok
                }
            }
        `),
    deleteAnnotation: gql(`
        mutation deleteAnnotationMutation($id: ID!){
            deleteAnnotationMutation(id: $id){
                ok
            }
        }`),
    ticketContentValid: gql(`
             mutation TicketContentMutation($id: ID!, $valid: Boolean!){
                ticketContentMutation(input: {id: $id, valid: $valid}){
                    ticketContent{
                        valid
                    }
                }
            }
        `),
  },
};
