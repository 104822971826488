import React, { memo, useEffect, useMemo, useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import {faSignOut, faTimes, faPalette} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReloadIcon from "../../../../assets/images/reload-icon.svg";

import { path } from "../../../../utils/api/apiQueries";
import useGlobal from "../../../../store";

const LogoutConfigButtons = ({ getUserInfo, isLoadingData }) => {
  const [configOpened, setConfigOpened] = useState(false);

  const [{ gradients, activeGradient }, { logout, resetGeneral, resetTicket, setBackGradient }] = useGlobal();

  const [revokeRefreshToken] = useMutation(path.mutation.revokeToken);

  const getLoggedout = () => {
    const tokenAuth = JSON.parse(localStorage.getItem("tokenAuth"));
    if (tokenAuth && tokenAuth.refreshToken) {
      revokeRefreshToken({
        variables: {
          refreshToken: tokenAuth.refreshToken,
        },
      });
    }
    localStorage.clear();
    logout();
    resetGeneral();
    resetTicket();
  };

  useEffect(() => {
    localStorage.setItem("activeGradient", activeGradient);
  }, [activeGradient]);

  const uiGradients = useMemo(() => {
    return (
      <div className='logout-config-colors'>
        {Object.keys(gradients).map((id) => (
          <div
            onClick={() => setBackGradient(id)}
            key={id}
            className='logout-config-colors-single'
            style={{
              background: gradients[id].backGroundCol,
              transform: `scale(${activeGradient === id ? 1.2 : 1})`,
            }}
          ></div>
        ))}
      </div>
    );
  }, [gradients, activeGradient, setBackGradient]);

  const { secondaryContainer, textColor } = useMemo(() => {
    return gradients[activeGradient];
  }, [gradients, activeGradient]);

  const LogoutConfigButton = ({ action, icon }) => {
    return (
      <div
        className='logout-config-button'
        style={{
          backgroundColor: secondaryContainer,
          transition: ".3s ease",
        }}
        onClick={action}
      >
        <FontAwesomeIcon
                icon={icon}
                color={textColor}
                size={'3x'}
            />
      </div>
    );
  };

  return (
    <div className='logout-config'>
      <div className={`reload-data-user ${isLoadingData ? "loading" : ""}`} onClick={getUserInfo}>
        <img src={ReloadIcon} alt='Reload icon' />
      </div>
      <LogoutConfigButton
        action={() => setConfigOpened(!configOpened)}
        icon={configOpened ? faTimes : faPalette}
        configOpened={configOpened}
      />
      {configOpened && uiGradients}

      <LogoutConfigButton
        action={getLoggedout}
        icon={faSignOut}
      />
    </div>
  );
};

export default memo(LogoutConfigButtons);
