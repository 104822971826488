import React, {useEffect} from 'react';
import {Switch, Route} from "react-router";

import RolesRoute from "../components/HOC/RolesRoute";
import Modal from "../components/modal";
import {InfoWrapper} from './main/InfoWrapper';
import PhotoAnnotation from './main/PhotoAnnotation';
import SupervisorContent from './main/SupervisorContent';
import TicketContent from './main/TicketContent';
import TicketsList from "./main/TicketsList";

import useGlobal from "../store";
import useColorMode from "../hooks/useColorMode";

export const Main = () => {
    const [{activeGradient, gradients, userInfo}, {setBackGradient, toggleModal}] = useGlobal();

    useEffect(() => {
        const colToSet = localStorage.getItem('activeGradient');
        setBackGradient(colToSet);
    }, [setBackGradient]);

    useEffect(() => {
        if (!userInfo.companyId)
            toggleModal();
    }, [])

    const styles = useColorMode();

    return (
        <>
            <div
                className="app"
                style={{
                    background: gradients[activeGradient].backGroundCol,
                    transition: '.3s ease',
                }}
            >
                <div
                    className='ticket-content-wrapper no-select'
                    style={{
                        ...styles,
                        boxShadow: `0 0 24px -9px ${gradients[activeGradient].accentColor}`,
                    }}>
                    <Switch>
                        <RolesRoute
                            exact path='/main'
                            forOperator={TicketContent}
                            forSupervisor={SupervisorContent}
                        />
                        <Route exact path='/main/annotation' component={PhotoAnnotation}/>
                        <Route exact path='/main/tickets-list' component={TicketsList}/>
                        <Route exact path='/main/ticket' component={TicketContent}/>
                    </Switch>
                </div>
                <InfoWrapper/>
            </div>
            <Modal modalType={'SidePanelModal'}/>
        </>
    );
};
