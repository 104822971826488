import React from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from "react-router";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from "./components/HOC/PrivateRoute";
import MessageManager from "./components/MessageManager";
import {Info} from './pages/Info';
import {Login} from './pages/Login';
import {Main} from './pages/Main';
import {ApolloProvider} from '@apollo/react-hooks';

// apollo client config
import client from "./utils/api/apolloClientConfig";

import './assets/styles/index.scss';

//root component to be inserted into DOM
const App = () => {
    return (
        <ErrorBoundary>
            <ApolloProvider client={client}>
                <Router>
                    <Switch>
                        <Route path="/info" component={Info}/>
                        <Route exact path="/login" component={Login}/>
                        <PrivateRoute path="/main" component={Main}/>
                        {/*<Route component={() => <div>Not found</div>}/>*/}
                        <Redirect from="*" to="/main"/>
                    </Switch>
                </Router>
                <MessageManager/>
            </ApolloProvider>
        </ErrorBoundary>
    );
};

ReactDOM.render(<App/>, document.getElementById('root'));