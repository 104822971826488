import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {faTimesCircle} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useGlobal from "../../store";
import Spinner from "../Spinner";

const TicketImage = ({img}) => {
    const history = useHistory();
    const [{userInfo: {role},}, globalActions] = useGlobal();
    const {setActiveTicketPhoto} = globalActions;
    const {id, urlPhoto, valid, annotationSet} = img;

    const [imgLoaded, setImgLoading] = useState(false);

    const setPhotoToAnnotate = () => {
        setActiveTicketPhoto(id);
        history.push({
            pathname: role === "SUPERVISOR"
                ? "./annotation"
                : "./main/annotation",
            search: id,
        });
    };

    const setImgLoadingStatus = useCallback(() => {
        setImgLoading(!imgLoaded);
    }, [])

    return (
        <div
            key={id}
            className='image-placeholder-wrapper'
            onClick={setPhotoToAnnotate}
        >
            <div
                className='image-placeholder'
                style={{}}
            >
                <img
                    onLoad={setImgLoadingStatus}
                    src={urlPhoto}
                    alt="ticket"
                />
                {!imgLoaded && <Spinner/>}

                {valid && <div className='ticket-image-overlay'>
                    <h1>{annotationSet.edges.length}</h1>
                </div>}

                {(imgLoaded && !valid) && <div
                    className='ticket-image-overlay'
                    style={{
                        backgroundColor: 'rgba(0,0,0,.4)',
                    }}>
                    <FontAwesomeIcon icon={faTimesCircle} color={'fff'}/>
                    {/*<h2>invalid</h2>*/}
                </div>}
            </div>
        </div>
    )
};

export default TicketImage;
