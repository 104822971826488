import React from "react";

const Spinner = () => {

    return <div className='img-spinner'>
        <div className="lds-default"
            // style={{
            //     background: gradients[activeGradient].secondaryContainer,
            // }}
        >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>;
};

export default Spinner;
