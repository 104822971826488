import React, {useCallback, useEffect, useRef, useState,} from 'react';
import {useHistory} from "react-router-dom";

import DrawAnnotation from "../../components/DrawAnnotation.js";
import Magnifier from "../../components/Magnifier";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import AdditionalMenu from "./photoAnnotation/AdditionalMenu";
import ObjectTitle from "./photoAnnotation/ObjectTitle";

import {getSizeByRef} from '../../utils/annotations/getSizeByRef';
import {path} from "../../utils/api/apiQueries";
import parseUrl from "../../utils/parseUrl";
import useGlobal from "../../store";

const PhotoAnnotation = () => {
    const [size, setSize] = useState({});
    const [refScrollTop, setRefScrollTop] = useState(0);
    const [activePhoto, setActivePhoto] = useState(null);
    const [isImgLoaded, setIsImageLoaded] = useState(false);
    const [showObjectTitle, setShowObjectTitle] = useState(null);
    const [toggledMagnifier, setToggledMagnifier] = useState(false);
    const [resSize, setResSize] = useState(350);
    const [resScale, setResScale] = useState(1.5);

    const history = useHistory();

    const [{ticketInfo, mousePos}, {setTicketByContent, showMessage}] = useGlobal();
    const {id, activePhotoId, ticketContents} = ticketInfo;

    const imgRef = useRef(null);
    const wrapRef = useRef(null);

    const handleResize = () => {
        setSize(getSizeByRef(imgRef));
    };

    const [getTicketImage, {data: _ticketImage}] = useLazyQuery(path.query.getTicketPhotoById);

    // const [, {refetch: refetchTicket}] = useLazyQuery(path.query.getTicketById);

    const [setInvalid, {data: validStatus, error: validError}] = useMutation(path.mutation.ticketContentValid);

    useEffect(() => {
        const photoId = parseUrl(window.location.href);
        if (!photoId || id) return;
        getTicketImage({
            variables: {
                id: photoId
            },
            fetchPolicy: 'network-only'
        });
    }, [validStatus, getTicketImage, id]);

    useEffect(() => {
        if (!_ticketImage) return;
        setTicketByContent(_ticketImage.ticketContent);
    }, [_ticketImage, setTicketByContent]);

    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
        return () => window.removeEventListener('resize', handleResize, false);
    }, []);

    useEffect(() => {
        isImgLoaded && handleResize()
    }, [isImgLoaded]);

    useEffect(() => {
        setActivePhoto(ticketContents.find(({id}) => id === activePhotoId));
    }, [activePhotoId, ticketContents]);

    const checkScrollTop = useCallback(() => {
        setRefScrollTop(wrapRef.current.scrollTop);
    }, [wrapRef]);


    const setValidStatus = useCallback(() => {
        setInvalid({
            variables: {
                id: activePhotoId,
                valid: !activePhoto.valid
            },
            cachePolicy: 'no-cache'
        }).catch(console.log);
    }, [setInvalid, activePhoto, activePhotoId]);

    useEffect(() => {
        if (!validStatus) return;
        if (!activePhoto.valid) {
            setActivePhoto({
                ...activePhoto,
                valid: true
            });
        }

        showMessage({
            type: 'success',
            body: `Фото змінено на ${activePhoto.valid ? 'невалідне' : 'валідне'}`
        });

        if (activePhoto.valid) {
            setActivePhoto({
                ...activePhoto,
                valid: false
            });
            history.goBack();
        }
    }, [validStatus, history, showMessage]);


    useEffect(() => {
        if (!validError) return;
        showMessage({
            type: 'failure',
            body: `Невдалося змінити статус фото`
        });
    }, [validError, showMessage]);

    const toggleMagnifier = useCallback(() => setToggledMagnifier(!toggledMagnifier), [setToggledMagnifier, toggledMagnifier]);
    return (
        <div
            className='photo-annotation-wrapper'
            onScroll={checkScrollTop}
        >
            {activePhoto &&
            <>
                {toggledMagnifier && <Magnifier
                    photo={activePhoto.urlPhoto}
                    mousePos={mousePos}
                    photoSize={size}
                    magnifierSett={{resSize, resScale}}
                    topScrolled={refScrollTop}
                />}

                <AdditionalMenu
                    valid={activePhoto.valid}
                    toggledMagnifier={toggledMagnifier}
                    magnifierSett={{resSize, resScale}}
                    actions={{setValidStatus, toggleMagnifier, setResSize, setResScale}}
                    photoUrl={activePhoto.urlPhoto}
                />

                <div
                    className='image-annotation'
                    ref={wrapRef}
                >

                    <img
                        alt='image to annotate'
                        ref={imgRef}
                        onLoad={() => setIsImageLoaded(true)}
                        src={activePhoto.urlPhoto}
                    />

                    <DrawAnnotation
                        settings={size}
                        refScrollTop={refScrollTop}
                        activePhoto={activePhoto}
                        setShowObjectTitle={setShowObjectTitle}
                    />
                </div>
                {showObjectTitle &&
                <ObjectTitle
                    hoveredObject={showObjectTitle}
                    imgSize={size}
                    topScrolled={refScrollTop}
                />
                }
            </>
            }
        </div>
    )
};

export default PhotoAnnotation;
