import React, {useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faCalendarCheck,
    faClock,
    faCreditCard,
    faEdit,
    faEnvelopeSquare,
    faHeadset,
    faMoneyBillWaveAlt,
    faPhoneSquareAlt,
    faPlanetMoon,
    faSignInAlt,
    faUserFriends,
} from "@fortawesome/pro-light-svg-icons";

export const FeatureCard = ({data}) => {
    const {icon, text} = data;

    const iconToShow = useMemo(() => {
        switch (icon) {
            case 'faEdit':
                return faEdit;
            case 'faPhoneSquareAlt':
                return faPhoneSquareAlt;
            case 'faEnvelopeSquare':
                return faEnvelopeSquare;
            case 'faSignInAlt':
                return faSignInAlt;
            case 'faMoneyBillWaveAlt':
                return faMoneyBillWaveAlt;
            case 'faCreditCard':
                return faCreditCard;
            case 'faPlanetMoon':
                return faPlanetMoon;
            case 'faCalendarCheck':
                return faCalendarCheck;
            case 'faClock':
                return faClock;
            case 'faHeadset':
                return faHeadset;
            case 'faUserFriends':
                return faUserFriends
        }
    }, []);

    return <div className={'feature-card-wrapper'}>
        <div className='feature-card flex-column-c-c'>
            <FontAwesomeIcon
                icon={iconToShow}
                color={'#000'}
                size='3x'
            />
            <h1>{text}</h1>
        </div>
    </div>
};
