import useGlobal from "../store";
import {useMemo} from "react";

const useColorMode = () => {
    const [globalState] = useGlobal();
    const {activeGradient, gradients} = globalState;

    return useMemo(() => {
        const {primaryContainer, textColor} = gradients[activeGradient];
        return {
            background: primaryContainer,
            color: textColor
        }
    }, [activeGradient, gradients]);
};
export default useColorMode;