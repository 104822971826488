import React, {useEffect, useMemo, useState} from 'react';

import {useQuery} from "@apollo/react-hooks";

import useGlobal from "../../store";

import {path} from "../../utils/api/apiQueries";

import useTicketImages from "../../hooks/useTicketImages";
import {useImagePlaceholder} from "../../hooks/useImagePlaceholder";

import parseUrl from "../../utils/parseUrl";

import '../../assets/styles/pages/ticketContent.scss';

const TicketContent = () => {
    const [globalState, globalActions] = useGlobal();
    const {setTicketContent} = globalActions;
    const {ticketContents,} = globalState.ticketInfo;
    const {companyId, role, id: _assignee} = globalState.userInfo;

    const fetchOptions = useMemo(() => {
        if (role === 'SUPERVISOR') {
            const ticketId = parseUrl(window.location.href);
            return {
                variables: {
                    id: ticketId
                },
            }
        }
        if (role === 'OPERATOR') {
            return {
                variables: {
                    status: 'in_progress',
                    company: companyId,
                    assignee: _assignee,
                },
            }
        }
    }, [companyId, role, _assignee]);


    const fetchPath = useMemo(() => {
        const {getTicketById, getFirstTicket} = path.query;
        return role === "SUPERVISOR" ? getTicketById : getFirstTicket
    }, [role]);


    const [ticketData, setTicketData] = useState(null);

    const {data} = useQuery(fetchPath, {
        ...fetchOptions,
        fetchPolicy: 'no-cache',
        skip: !companyId
    });

    useEffect(() => {
        if (!data) return;
        if (data.allTickets && data.allTickets.edges[0].node) {
            setTicketData(data.allTickets.edges[0].node);
        }
        if (data.ticket) {
            setTicketData(data.ticket)
        }
    }, [data, setTicketData]);

    useEffect(() => {
        if (!ticketData) return;
        setTicketContent(ticketData);

    }, [ticketData, setTicketContent]);


    const [placeholders] = useImagePlaceholder(18);
    const [imagesArr] = useTicketImages(ticketContents);

    return <div className='placeholders-wrapper'>
        {imagesArr.length > 0 ? imagesArr : placeholders}
    </div>
};

export default TicketContent;
