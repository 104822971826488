import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-light-svg-icons";
import React, {useMemo} from "react";
import SubMenuItem from "./SubMenuItem";


const Magnifier = ({toggledMagnifier, toggleMagnifier, magnifierSett, setResScale}) => {
    const {resScale} = magnifierSett;

    const magnifierSizes = [
        {
            title: '1.5x',
            value: 1.5
        },
        {
            title: '2x',
            value: 2
        },
        {
            title: '3x',
            value: 3
        },
    ];

    const renderedSubButtons = useMemo(() =>
        magnifierSizes.map(item =>
            <SubMenuItem
                resScale={resScale}
                key={item.title}
                action={setResScale}
                {...item}
            />
        ), [resScale, magnifierSizes, setResScale]);

    return <>
        <div className="actions-menu-item">
            <FontAwesomeIcon
                className={toggledMagnifier ? 'toggled-button' : ''}
                icon={faSearch}
                size={'3x'}
                onClick={toggleMagnifier}
            />
        </div>

        {toggledMagnifier &&
        <div style={{
            padding: '10px 0',
            width: '100%',
            animation: '.3s initialAppear'
        }}>
            {renderedSubButtons}
        </div>
        }
    </>
}
export default Magnifier;
