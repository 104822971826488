const supervisorState = {
    takenBySupervisor: false
};

const setSupervisorTaken = (store, data) => {
    store.setState({
        takenBySupervisor: data
    });
};

const supervisorActions = {
    setSupervisorTaken
};

export {supervisorActions, supervisorState};
