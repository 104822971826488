import React from 'react';
import Slider from 'react-slick';

export const InfoSlider = ({sliderItems}) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 10000,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
        ]
    };

    return (
        <Slider {...sliderSettings}>
            {sliderItems}
        </Slider>
    )
};