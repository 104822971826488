
const ticketState = {
  ticketInfo: {
    id: null,
    tip: "",
    title: "",
    active: false,
    createdAt: null,
    ticketContents: [],
    activePhotoId: null,
    additionalInfo: "",
    // annotationSet: []
  },
  tickets: [],
};

const setTicketContent = (store, data = {}) => {
    const { id, title, createdAt, ticketContents, additionalInfo } = data;

    let ticketContentsArr = [];
    if (ticketContents) {
        ticketContentsArr = ticketContents.edges.map(item => {
            return {...item.node};
        });
    }
    const ticketInfo = {
      ...store.state.ticketInfo,
      active: !!ticketContentsArr.length,
      id,
      title,
      createdAt,
      additionalInfo,
      ticketContents: ticketContentsArr,
    };

    store.setState({
        ...store.state.ticketState,
        ticketInfo
    });
};

const setTicketByContent = (store, data) => {
    const {
        ticket: {
            id,
            title,
            additionalInfo,
            createdAt 
        },
        id: photoId,
        urlPhoto,
        valid,
        tip,
        annotationSet
    } = data;

    const ticketInfo = {
      ...store.state.ticketInfo,
      id,
      title,
      createdAt,
      tip,
      active: true,
      ticketContents: [
        {
          id: photoId,
          urlPhoto,
          valid,
          tip,
          additionalInfo,
          annotationSet,
        },
      ],
      activePhotoId: photoId,
    };
    store.setState({
        ticketInfo
    });
};


const setPhotoTip = (store, tip = null) => {
    const toSet = {
        ...store.state.ticketInfo,
        tip
    };
    store.setState({
        ...store.state,
        ticketInfo: toSet
    });
};

const setActiveTicketPhoto = (store, id = null) => {
    const ticketInfo = {
        ...store.state.ticketInfo,
        activePhotoId: id,
    };
    store.setState({
        ticketInfo
    });
};

const setTicketsBySupervisor = (store, data) => {
    store.setState({
        tickets: data
    });
};

const resetTicket = store => {
    console.log("reseting ticket")
    store.setState({
        ...ticketState
    });
};

const ticketActions = {
    resetTicket,
    setPhotoTip,
    setTicketContent,
    setTicketByContent,
    setActiveTicketPhoto,
    setTicketsBySupervisor
};


export {ticketActions, ticketState};
