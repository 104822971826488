import React, { useMemo } from "react";
import classNames from "classnames";

import useGlobal from "../../../store";
import useColorMode from "../../../hooks/useColorMode";

const compWrapClass = classNames("container", "ticket-info-wrapper");

const TicketInfo = () => {
  const [globalState] = useGlobal();
  const { activeGradient, gradients } = globalState;
  const { tip, createdAt, title, additionalInfo, ticketContents, activePhotoId } = globalState.ticketInfo;
  const urlParams = window.location.search;
  let isSingleTicket = !urlParams.includes(activePhotoId) ? false : true;
  const infoText = {
    tip: "Підказка: ",
    date: "Дата: ",
    title: "Заголовок: ",
    comment: "Коментар: ",
  };
 
  const styles = useColorMode();
  const objAdditionalInfo = additionalInfo ? JSON.parse(additionalInfo) : "";
  const currentTicketContents = ticketContents.find((item) => item.id === activePhotoId);
  const objSingleAdditionalInfo = currentTicketContents?.additionalInfo ? JSON.parse(currentTicketContents?.additionalInfo) : "";
  const currentAdditionalInfo = !isSingleTicket
    ? objAdditionalInfo?.comment
      ? objAdditionalInfo.comment
      : "No comments yet."
    : objSingleAdditionalInfo?.comment
    ? objSingleAdditionalInfo.comment
    : "No comments yet.";

  const { secondaryContainer, accentColor } = useMemo(() => {
    return gradients[activeGradient];
  }, [activeGradient, gradients]);

  const ticketInfoArr = useMemo(() => {
    const ticketInfoObject = {
      tip,
      title,
      date: createdAt ? new Date(createdAt).toUTCString() : null,
      comment: currentAdditionalInfo,
    };

    return Object.keys(ticketInfoObject).map((info) => {
      return (
        <h1
          key={info}
          data-key={info}
          style={{
            background: secondaryContainer,
          }}
        >
          <span>{infoText[info]}</span>
          {ticketInfoObject[info] || "-"}
        </h1>
      );
    });
  }, [tip, title, secondaryContainer, additionalInfo, activePhotoId, currentAdditionalInfo]);

  return (
    <div
      className={compWrapClass}
      style={{
        ...styles,
        boxShadow: `0 0 24px -9px ${accentColor}`,
      }}
    >
      <h1>Тікет інфо</h1>
      <div className='ticket-info-wrapper-container'>{ticketInfoArr}</div>
    </div>
  );
};

export default TicketInfo;
