export const formatAnnotations = (annotationSet) => {
    return annotationSet.edges.map(({node: {id, coordinate, object, additionalInfo}}) => {
        const addInf = additionalInfo ? {...JSON.parse(additionalInfo)} : {};
        return {
            id,
            ...JSON.parse(coordinate),
            ...addInf,
            ...object,
        }
    });
};

export default  formatAnnotations