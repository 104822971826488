import React, {useEffect, useMemo} from "react";
import {Link} from "react-router-dom";
import NumberFormat from 'react-number-format';

import useForm from "../../hooks/useForm";

import '../../assets/styles/pages/infoPage/applicationForm.scss';
import useAxios from "axios-hooks";
import isEmail from "validator/es/lib/isEmail";
import useGlobal from "../../store";

export const ApplicationForm = () => {
    const [, {showMessage}] = useGlobal();

    const {handleInputChange, inputs, resetInputs} = useForm({
        fname: {
            value: '',
            placeholder: 'Ім\'я',
        },
        lname: {
            value: '',
            placeholder: 'Прізвище',
        },
        email: {
            value: '',
            placeholder: 'email',
        },
        phone: {
            value: '',
            placeholder: 'телефон',
        },
    });

    const {fname, lname, email, phone} = useMemo(() => inputs, [inputs]);

    let REACT_APP_REST_API='https://api.outforz.com/api/';

    const [{data, error}, postApplication] = useAxios(
        {
            // url: process.env.REACT_APP_REST_API + "new_operator_request/",
            url: REACT_APP_REST_API + "new_operator_request/",
            method: "POST"
        },
        {manual: true}
    );

    const handleSubmit = e => {
        e.preventDefault();
        if (!phone.value || !fname.value || !lname.value) {
            showMessage({
                type: 'warning',
                body: 'Заповніть, будь ласка, всі поля!'
            });
            return;
        }

        if (!isEmail(email.value)) {
            showMessage({
                type: 'warning',
                body: 'Електронна пошту введено не вірно!'
            });
            return;
        }

        if (isEmail(email.value) && phone.value && fname.value && lname.value)
            try {
                postApplication({
                    data: {
                        first_name: fname.value,
                        last_name: lname.value,
                        phone: phone.value,
                        email: email.value
                    }
                })
            } catch (e) {

            }
    };

    useEffect(() => {
        if (error) {
            showMessage({
                type: 'error',
                body: 'Заявку на надіслано, зачекайте та спробуйте ще раз!'
            });
            return;
        }
        if (data && data.ok) {
            showMessage({
                type: 'success',
                body: 'Заявку успішно надіслано!'
            });
            resetInputs();
        }
    }, [data, error, resetInputs, showMessage]);


    const formInputs = useMemo(() => {
        return Object.keys(inputs).map((item, index) => {
                const {value, placeholder} = inputs[item];
                return item === 'phone'
                    ? <NumberFormat
                        key={index}
                        format="+380 ( ## ) ###-##-##"
                        allowEmptyFormatting mask="_"
                        value={value}
                        onChange={handleInputChange}
                        name={item}
                    />
                    : <input
                        key={index}
                        placeholder={placeholder}
                        value={value}
                        name={item}
                        onChange={handleInputChange}
                    />
            }
        );
    }, [inputs, handleInputChange]);


    return <form className='application-form'>
        <h1>Лишити заявку: </h1>
        {formInputs}
        <button onClick={handleSubmit}>
            Надіслати
        </button>

        <p>Вже зареєстрований?
            <Link to='/login'>
                авторизуватися
            </Link>
        </p>
    </form>
};
