import React, {memo} from 'react';

import useGlobal from "../../../../store";


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/pro-light-svg-icons";

const UserInfoHeader = () => {
    const [
        {
            userInfo: {
                lastName,
                username,
                avatar,
                firstName,
                fathersName,
                companyName,
            },
            modalState,
            gradients,
            activeGradient
        },
    ] = useGlobal();

    return (
        <div className='user-info-header'>
            <div className='user-info-wrapper-logo-name'>
                <h1>OUTFORZ</h1>
                <div
                    className='user-info-wrapper-name'
                >
                    <h2>{firstName} {lastName} {fathersName}</h2>
                    {!modalState && <h3>{companyName}</h3>}
                    {modalState &&
                    <h3>{username}</h3>
                    }
                </div>
            </div>

            <div
                className='user-info-wrapper-avatar'
                style={{
                    backgroundColor: gradients[activeGradient].secondaryContainer
                }}
            >
                {!avatar && <FontAwesomeIcon
                    icon={faUser}
                    color={gradients[activeGradient].textColor}
                    size={'3x'}
                />}
                {avatar && <img
                    src={avatar}
                    alt="avatar"/>}
            </div>

        </div>
    );
};
export default memo(UserInfoHeader);