import React, {useEffect} from 'react';

// import classes from 'classnames';

import useGlobal from "../store";
import InfoMessage from "./messageManager/InfoMessage";


const MessageManager = () => {
    const [{messageStatus}, {showMessage}] = useGlobal();
    const {type} = messageStatus;

    const duration = 3000;

    let compToShow = null;
    switch (type) {
        case 'success': {
            compToShow = <InfoMessage decline={showMessage} messageStatus={messageStatus}/>;
            break;
        }
        case 'warning': {
            compToShow = <InfoMessage decline={showMessage} messageStatus={messageStatus}/>;
            break;
        }
        case 'error': {
            compToShow = <InfoMessage decline={showMessage} messageStatus={messageStatus}/>;
            break;
        }
        default:
            compToShow = null;
    }

    useEffect(() => {
        let id = type && setTimeout(() => showMessage({type: 'reset', body: null}), duration);
        return () => clearTimeout(id);
    }, [type, showMessage]);

    return compToShow;
};

export default MessageManager;