import React, {useMemo} from 'react';

import TicketImage from '../components/ticketImage/ticketImage'

const useTicketImages = (ticketContents) => {

    const renderedImages = useMemo(() => {
        let imgs = [];
        ticketContents.forEach(photo => {
            if (photo.urlPhoto)
                imgs.push(<TicketImage
                    key={photo.id}
                    img={photo}/>
                )
        })
        return imgs;
    }, [ticketContents]);

    return [renderedImages];
};
export default useTicketImages;
