import checkIfBaseEnc from "../../utils/checkIfBaseEnc";

const decodeBase = baseId => {
    return atob(baseId).split(':').pop()
};

const formatToBeSent = (annotationInfo, annotations, _userId, {id: _id}, role) => {
    const {objectId, price, salePrice, quantity} = annotationInfo;
    const {id, ...coordinate} = annotations[annotations.length - 1];

    const checkedVariables = {
        object: checkIfBaseEnc(objectId) ? decodeBase(objectId) : objectId,
        createdBy: checkIfBaseEnc(_userId) ? decodeBase(_userId) : _userId,
        ticketContent: checkIfBaseEnc(_id) ? decodeBase(_id) : _id,
    };
    let variables = {
        active: true,
        ...checkedVariables,
        additionalInfo: JSON.stringify({price, salePrice, quantity}, null, 2),
        coordinate: JSON.stringify(coordinate, null, 2)
    };

    if (annotationInfo && annotationInfo.id) {
        variables.id = annotationInfo.id;
        variables.coordinate = JSON.stringify(annotationInfo.coordinate, null, 2);
    }


    if (role === "SUPERVISOR") {
        variables.supervised = true;
    }
    return {variables};
};

export default formatToBeSent;