import React, {useMemo} from "react";
import useGlobal from "../../../../store";

const SingleStat = ({id, stats}) => {

    const [{gradients, activeGradient}] = useGlobal();

    const statName = useMemo(() => {
        switch (id) {
            case 'payment':
                return 'Оплата';
            case 'annotationsDone':
                return 'Перевірених аннотацій';
            case 'annotationsTotal':
                return 'Всього анотацій';
            case 'annotationsCorrect':
                return 'Правильних аннотацій';
            case 'annotationsSupervised':
                return 'Неправильних аннотацій';
            default:
                return id
        }
    }, [id, stats]);

    return <div
        className="single-stat-wrapper"
        key={id}
    >
        <div
            className='single-stat'
            style={{
                background: gradients[activeGradient].secondaryContainer,
            }}
        >
            <h2>{statName}</h2>
            <h1>{stats[id]}</h1>
        </div>
    </div>
};
export default SingleStat;