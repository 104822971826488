const userState = {
    token: null,
    isAuthorized: false,
    userInfo: {
        isInitial: true,
        firstName: '',
        role: '',
        lastName: '',
        fathersName:'',
        username: '',
        companies: [],
        companyName: '',
        companyId: null,
        companyTicketsCount: null,
        avatar: '',
        id: null,
        stats: {},
        fetched: false,
    },
    operatorBySupervisor: null
};

const setAuthToken = (store, token) => {
    store.setState({token});
};


//TODO: change active company selection*

const setUserData = (store, data) => {
    const {
        role,
        avatar,
        company,
        username,
        lastName,
        firstName,
        fathersName,
        availableTicketsRemain,
        isInitial = false,
        assigneeTickets
    } = data;

    const companies = company.edges.map(company => company.node);
    const ticketsInProgress = assigneeTickets?assigneeTickets.edges.map(ticket => ticket.node):[];

    const userInfo = {
        ...store.state.userInfo,
        firstName,
        lastName,
        fathersName,
        username,
        avatar,
        role,
        availableTicketsRemain,
        companies,
        ticketsInProgress,
        // companyName: companies[0].name, //TODO *
        // companyId: companies[0].id, //TODO *
        id: data.id,
        isInitial,
    };
    store.setState({
        ...store.state,
        isAuthorized: true,
        userInfo
    });
};

const setActiveCompany = (store, company) => {
    // console.log(company);
    let userInfo = {};
    if (company) {
        const {id, name, availableTicketsRemain} = company;
        userInfo = {
            ...store.state.userInfo,
            availableTicketsRemain,
            companyName: name,
            companyId: id,
        }
    } else {
        const {id, name} = store.state.userInfo.companies[0];
        userInfo = {
            ...store.state.userInfo,
            companyId: id,
            companyName: name,
        }
    }

    store.setState({
        userInfo
    });
};

const setCompanies = (store, data) => {
    // console.log(data)
    store.setState({
        ...store.state,
        userInfo: {
            ...store.state.userInfo,
            companies: data
        }
    })
};

const setUserStatistics = (store, data) => {
    const stats = {
        ...data,
    };

    const userInfo = {
        ...store.state.userInfo,
        stats
    };
    store.setState({userInfo});
};

const setSelectedOperator = (store, data) => {
    // console.log(data)
    store.setState({
        operatorBySupervisor: data
    });
};

const logout = (store, data) => {
    store.setState({...userState});
};

const userActions = {
    logout,
    setUserData,
    setCompanies,
    setAuthToken,
    setActiveCompany,
    setUserStatistics,
    setSelectedOperator
};

export {userActions, userState};