import React, {useMemo} from "react";
import uiid from 'uuid';
import useGlobal from "../store";

const SingleCard = ({
                        data: {
                            id,
                            image,
                            background,
                            text,
                            ticketCount
                        },
                        selectCard
                    }) => {

    const [{gradients, activeGradient}] = useGlobal();

    const renderedTextItems = useMemo(() => {
        return Object.keys(text).map(key => {
            return (
                <h1 key={uiid.v4()}>{text[key]}</h1>
            );
        })
    }, [text]);

    const styles = useMemo(() => {
        return {
            background: background
                ? `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${background})`
                : gradients[activeGradient].secondaryContainer,
            backgroundSize: 'cover',
            fontWeight: 600,
            color: background ? '#fff' : gradients[activeGradient].textColor
        }
    }, [background, gradients, activeGradient]);

    return (
        <div
            onClick={() => selectCard(id)}
            className='single-operator-wrapper'
        >
            <div
                className='single-operator'
                style={styles}
            >
                {image && <div className='single-operator-avatar'>
                    <img src={image} alt="avatar"/>
                </div>}
                <div className='single-operator-info'>
                    {renderedTextItems}
                </div>
                <div className='single-operator-stats'>
                    <h1>
                        {ticketCount}
                    </h1>
                </div>
            </div>
        </div>
    )
};


export default SingleCard;
