import React from "react";

import GeneralInfo from "./infoWrapper/GeneralInfo";
import {StartButton} from "./infoWrapper/StartButton";
import TicketInfo from "./infoWrapper/TicketInfo";
import UserInfo from "./infoWrapper/UserInfo";

import '../../assets/styles/pages/infoWrapper.scss';

export const InfoWrapper = () => {
    return (
        <div className='info-wrapper'>
            <UserInfo/>
            <TicketInfo/>
            <GeneralInfo/>
            <StartButton/>
        </div>
    );
};
