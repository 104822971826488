import React, { useState, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import LogoutConfigButtons from "../../pages/main/infoWrapper/userInfo/LogoutConfigButtons";
import UserInfoHeader from "../../pages/main/infoWrapper/userInfo/UserInfoHeader";
import UserInfoStats from "../../pages/main/infoWrapper/userInfo/UserInfoStats";
import { useLazyQuery } from "@apollo/react-hooks";

import { path } from "../../utils/api/apiQueries";
import useGlobal from "../../store";
import useColorMode from "../../hooks/useColorMode";

const UserInfoDetailed = (transition) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [
    {
      ticketInfo: { active },
      userInfo: { stats, companies, companyName, id },
      gradients,
      activeGradient,
      modalState,
      token,
    },
    globalActions,
  ] = useGlobal();

  const { toggleModal, setActiveCompany, showMessage, setUserStatistics, setCompanies } = globalActions;
  // const className = classNames('user-info-detailed-wrapper-modal', modalState ? 'toggled' : '');
  const className = classNames("user-info-detailed-wrapper-modal");

  const history = useHistory();

  const selectCompany = useCallback(
    (item) => {
      if (active) {
        showMessage({
          type: "warning",
          body: "Завершіть активний тікет спочатку",
        });
        return;
      }
      history.push("/main");
      toggleModal();
      setActiveCompany(item);
      localStorage.setItem("activeCompany", JSON.stringify(item, null, 2));
    },
    [active, history, setActiveCompany, showMessage, toggleModal]
  );

  const allCompanies = useMemo(() => {
    return companies.map((item) => (
      <h3
        key={item.id}
        onClick={() => selectCompany(item)}
        className='single-company'
        style={
          companyName === item.name
            ? {
                backgroundColor: gradients[activeGradient].accentColor,
                fontWeight: 600,
                opacity: 1,
                color: "#fff",
              }
            : {}
        }
      >
        {item.name}
        <span>({item.availableTicketsRemain})</span>
      </h3>
    ));
  }, [companies, companyName, selectCompany, activeGradient, gradients]);

  //get or reload data
  const [requestStatistics, { data: newStats, refetch: refetchStats }] = useLazyQuery(path.query.statistics);

  useEffect(() => {
    requestStatistics();
  }, []);

  const [fetchCompaniesInfo, { data, refetch }] = useLazyQuery(path.query.getCompanyInfo2);

  useEffect(() => {
    fetchCompaniesInfo({
      variables: {
        id,
      },
    });
  }, [token, id]);

  useEffect(() => {
    if (!data) return;
    if (data && data.user.company.edges) {
      setCompanies(data.user.company.edges.map((company) => company.node));
    }
  }, [data]);

  useEffect(() => {
    if (newStats && newStats.statistics) {
      setUserStatistics(newStats.statistics);
    }
  }, [modalState, newStats, setUserStatistics]);

  const showUserInfo = () => {
    refetch();
    refetchStats();
    setIsLoadingData(true);

     setTimeout(() => {
       setIsLoadingData(false);
     }, 1500);
  };

  const styles = useColorMode();

  return (
    <>
      <div className='user-info-detailed-wrapper' onClick={toggleModal} />
      <div
        className={className}
        style={{
          ...styles,
          transition,
          boxShadow: `0 0 24px -9px ${gradients[activeGradient].accentColor}`,
        }}
      >
        <UserInfoHeader />
        {companies && <div className='user-info-companies'>{allCompanies}</div>}
        <UserInfoStats />
        <div className='date-joined'>
          <h2>
            Дата реєстрації: <span>{stats["dateJoined"]}</span>
          </h2>
        </div>
        <LogoutConfigButtons getUserInfo={showUserInfo} isLoadingData={isLoadingData} />
      </div>
    </>
  );
};

export default UserInfoDetailed;
