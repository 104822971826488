import React from 'react';
import useGlobal from "../../store";

export const ImagePlaceholder = () => {
    const [{gradients, activeGradient}] = useGlobal();

    return (
        <div className='image-placeholder-wrapper'>
            <div
                className='image-placeholder'
                style={{
                    backgroundColor: gradients[activeGradient].secondaryContainer
                }}
            >
            </div>
        </div>
    );
};