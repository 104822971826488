import React, {useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrows, faCheck, faTimes,} from "@fortawesome/pro-light-svg-icons";

import useGlobal from "../../../store";

import MagnifierButton from "./additionalMenu/MagnifierButton";
import InfoComponent from "./additionalMenu/InfoComp";

const AdditionalMenu = ({
                            valid,
                            photoUrl,
                            toggledMagnifier,
                            magnifierSett,
                            actions: {
                                setValidStatus,
                                toggleMagnifier,
                                setResScale
                            }
                        }) => {

    const [{gradients, activeGradient}] = useGlobal();


    const inNewTab = useCallback(() => window.open(photoUrl, "_blank"), [window, photoUrl]);

    return (
        <div
            className='additional-actions'
            style={{
                background: gradients[activeGradient].secondaryContainer,
            }}
        >
            <div
                className='actions-menu'
            >
                <div className='actions-menu-item'>
                    <FontAwesomeIcon
                        icon={valid ? faTimes : faCheck}
                        size={'3x'}
                        onClick={setValidStatus}
                    />
                </div>

                <div className="actions-menu-item">
                    <FontAwesomeIcon
                        icon={faArrows}
                        size={'3x'}
                        onClick={inNewTab}
                    />
                </div>

                <MagnifierButton
                    photo={photoUrl}
                    magnifierSett={magnifierSett}
                    setResScale={setResScale}
                    toggleMagnifier={toggleMagnifier}
                    toggledMagnifier={toggledMagnifier}
                />

                <InfoComponent/>

            </div>
        </div>
    )
};
export default AdditionalMenu;
