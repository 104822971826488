import React, {useCallback, useMemo, useState} from "react";

import {v1} from "uuid"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/pro-light-svg-icons";

const InfoComponent = () => {
    const [infoHovered, setInfoHovered] = useState(false);

    const infoSteps = [
        "Щоб зберегти відмітку виберіть товар із списку, натисніть кнопку «Enter» на клавіатурі або кнопку «Готово» на сайті",
        "Щоб видалити відмітку утримуйте кнопку «Shift» на клавіатурі і лівою кнопкою мишки натисніть на відмітку, або натисніть на відмітку правой кнопкой мишки і із випадаючого списку виберіть «Delete».",
        "Щоб редагувати відмітку - натисніть правою кнопкой мишки на відмітку та виберіть «Edit»."
    ];

    const renderedSteps = useMemo(() => infoSteps.map(step => <li key={v1()}>{step}</li>), [])

    const handleInfoHover = useCallback(() => {
        setInfoHovered(!infoHovered)
    }, [infoHovered]);

    return <div className="actions-menu-item operator-how-to-container">
        <FontAwesomeIcon
            icon={faInfo}
            size={'3x'}
            onMouseEnter={handleInfoHover}
            onMouseLeave={handleInfoHover}
        />
        {infoHovered &&
        <div className="operator-how-to-content">
            <ul>
                {renderedSteps}
            </ul>
        </div>
        }
    </div>
}

export default InfoComponent;
