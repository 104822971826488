import React, {useCallback, useEffect, useMemo, useState} from "react";
import useForm from "../../../hooks/useForm";
import useGlobal from "../../../store";
import ObjectItem from "./subMenuAnnotation/objectItem";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/pro-light-svg-icons";

function SubMenuAnnotation(props) {
    const {
        lastAdded = {},
        lastAnnot,
        allObjects,
        setLastAnnot,
        handleCancel,
        setAllObjects,
        submitAnnotation,
        persistedObjects,
        toggleContextItem,
        showAnnotationMenu,
        showAnnotationMenu: {
            name,
            price: initialPrice,
            salePrice: initialSalePrice,
            quantity: initialQuantity,
        }
    } = props;

    const [{gradients, activeGradient, userInfo}, {showMessage}] = useGlobal();


    const {id} = lastAdded;

    const [selectedObject, setSelectedObject] = useState(null);
    const [objectsWordsArray, setObjectsWordsArray] = useState([]);
    const [inputToBeFilteredBy, setInputToBeFilteredBy] = useState([]);
    const [objectImageToShow, setObjectImageToShow] = useState(null);


    const {handleInputChange, inputs, resetInputs} = useForm({
        objectName: name || lastAnnot.name,
        price: initialPrice || lastAnnot.price,
        salePrice: initialSalePrice || lastAnnot.salePrice,
        quantity: initialQuantity || lastAnnot.quantity
    });

    let {objectName, price, salePrice, quantity} = useMemo(() => inputs, [inputs]);

    useEffect(() => {
        allObjects.forEach(object => {
            const {name, image} = object;
            if (objectName === name) {
                setSelectedObject(object);
                setObjectImageToShow(image);
            }
        });
        // console.log(showAnnotationMenu.name)
    }, [showAnnotationMenu, allObjects, setSelectedObject, setObjectImageToShow]);

    const arrayContainsArray = (superset, subset) => {
        return subset.every(value => {
            return superset.includes(value);
        });
    };

    useEffect(() => {
        let savedObjectsWordsArr = persistedObjects.map(object => object.name.toLowerCase());
        setObjectsWordsArray(savedObjectsWordsArr);
    }, [persistedObjects]);

    useEffect(() => {
        if ((objectName && objectName.value) || objectName.value === '') {
            setInputToBeFilteredBy(objectName.value.split(' ').map(word => word.toLowerCase()));
            return;
        }
        setInputToBeFilteredBy(objectName.split(' ').map(word => word.toLowerCase()));
    }, [objectName]);


    useEffect(() => {
        let filteredList = persistedObjects.filter((object, index) => {
            return arrayContainsArray(objectsWordsArray[index], inputToBeFilteredBy)
        });
        setAllObjects(filteredList);
    }, [inputToBeFilteredBy]);


    const handleObjectSelect = (item) => {
        if (selectedObject && (item.id === selectedObject.id)) {
            setSelectedObject(null);
            showObjectImage();
            return;
        }
        setSelectedObject(item);
        setObjectImageToShow(item.image);
    }

    const handleSubmit = useCallback(() => {
        const {price, salePrice, quantity} = inputs;

        if (!selectedObject) {
            showMessage({
                type: 'warning',
                body: 'Оберіть назву товару або введіть ціну '
            });
            return;
        }

        let annToSubmit = {
            objectId: selectedObject.id,
            price,
            salePrice,
            quantity
        };

        if (toggleContextItem) {
            const {id, x1, y1, x2, y2} = toggleContextItem;
            annToSubmit = {
                id,
                coordinate: {x1, y1, x2, y2},
                ...annToSubmit,
            };
        }

        submitAnnotation(annToSubmit);
    }, [inputs, handleObjectSelect, selectedObject, toggleContextItem]);


    const handleKeyPress = useCallback(e => {
        if (e.which === 13 || e.keyCode === 13) {
            handleSubmit();
        }
    }, [selectedObject, handleSubmit])


    useEffect(() => {
        window.addEventListener('keypress', handleKeyPress, false);
        return () => {
            window.removeEventListener('keypress', handleKeyPress, false);
        }
    }, [selectedObject, inputs]);

    const idToDelete = useMemo(() => {
        return (toggleContextItem && toggleContextItem.id) || id
    }, [toggleContextItem, id, lastAdded]);


    const handleKeyDown = useCallback(e => {
        if (e.keyCode === 27 || e.which === 27) {
            if (!idToDelete) return;
            handleCancel(idToDelete);
            showMessage({
                type: 'warning',
                body: 'Створення аннотації відміненно'
            });
        }
    }, [lastAdded, idToDelete, handleCancel]);


    const resetSelection = useCallback(() => {
        resetInputs();
        setSelectedObject(null);
        setObjectImageToShow(null);
        setLastAnnot({name: '', price: '', salePrice: '', quantity: ""});
    }, [objectName, lastAnnot, selectedObject, objectImageToShow]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown, false);
        return () => {
            window.removeEventListener('keydown', handleKeyDown, false);
        }
    }, [idToDelete, lastAdded]);


    // const style = useMemo(() => {
    //     if (x1) {
    //         return {
    //             left: .5 * width,
    //             top: .1 * height,
    //         }
    //     }
    // }, [x1, x2, selectedObject, width, height, activeGradient]);

    const showObjectImage = useCallback((img = null) => {
        // const imageUrl = img ? `https://outforz.s3.amazonaws.com/media/public/${img}` : null;
        setObjectImageToShow(img);
    }, [])


    const renderedObjects = useMemo(() => {
        return allObjects.map(item => {
            return <ObjectItem
                handleObjectSelect={handleObjectSelect}
                selectedObject={selectedObject}
                showHoveredImage={showObjectImage}
                key={item.id}
                item={item}
            />
        });
    }, [allObjects, selectedObject]);

    const {primaryContainer, secondaryContainer, textColor, accentColor} = useMemo(() => {
        return gradients[activeGradient];
    }, [gradients, activeGradient]);

    const searchVal = useMemo(() => typeof objectName === "string" ? objectName : objectName.value, [objectName]);

    return (
        <div
            className='sub-menu-annotation'
        >

            <div
                style={{
                    background: primaryContainer,
                    color: textColor
                }}
                className='sub-menu-annotation-content'
            >
                <h1>Знайти</h1>
                <div
                    className='sub-menu-annotation-name'
                >
                    <input
                        type="text"
                        placeholder={'назва'}
                        name="objectName"

                        value={searchVal}
                        onChange={handleInputChange}
                        style={{
                            width: '100%',
                            background: secondaryContainer,
                            color: textColor
                        }}
                    />
                    <FontAwesomeIcon
                        onClick={resetSelection}
                        icon={faTrashAlt}
                        size={'2x'}
                        color={'fff'}
                    />
                </div>

                <h1>Ціна</h1>
                <div className='prices-container'>
                    <input
                        style={{
                            background: secondaryContainer,
                        }}
                        type="text"
                        placeholder={'ціна'}
                        name='price'
                        value={price && price.value ? price.value : ''}
                        onChange={handleInputChange}
                    />

                    <input
                        style={{
                            background: secondaryContainer,
                        }}
                        type="text"
                        placeholder={'акційна ціна'}
                        name='salePrice'
                        value={salePrice?.value || ''}
                        onChange={handleInputChange}
                    />
                </div>

                <h1>Кількість</h1>
                <div className='sub-menu-annotation-name'>
                    <input
                        style={{
                            background: secondaryContainer,
                        }}
                        type="text"
                        placeholder={'кількість'}
                        name='quantity'
                        value={quantity?.value || ''}
                        onChange={handleInputChange}
                        style={{
                            width: '100%',
                            background: secondaryContainer,
                            color: textColor
                        }}
                    />

                </div>

                <h1>Назва товару</h1>
                {inputToBeFilteredBy && <ul>
                    {renderedObjects}
                </ul>}

                {selectedObject && <div className='sub-menu-annotation-buttons'>
                    <button
                        style={{
                            backgroundColor: accentColor
                        }}
                        onClick={handleSubmit}
                    >
                        Готово
                    </button>
                </div>}

            </div>
            {objectImageToShow && <div className='sub-menu-annotation-objectImage'>
                <img src={objectImageToShow} alt="objectImage"/>
            </div>}
        </div>
    )
}

export default SubMenuAnnotation;
