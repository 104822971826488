import React, {useCallback, useMemo} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from '@fortawesome/pro-light-svg-icons'

const PosArrow = ({setPlacement, placement, sideSize, currentPos, icon}) => {

    const setTo = useCallback(() => {
        const {
            setTopBot,
            setLeftRight
        } = setPlacement;

        if (placement === 'top' || placement === 'bottom') setTopBot(placement);
        if (placement === 'left' || placement === 'right') setLeftRight(placement);
    }, [placement]);

    return (
        <div
            className='pos-arrow'
            onClick={setTo}
            style={{
                [placement]: sideSize * .3,
            }}
        >
            <FontAwesomeIcon
                icon={icon}
                size={'3x'}
            />
        </div>
    )
};
export default PosArrow;