import React, {useMemo} from 'react';

import useGlobal from "../../../../store";
import SingleStat from "./SingleStat";

const UserInfoStats = () => {
    const [globalState] = useGlobal();
    const {stats} = globalState.userInfo;

    let statsTiles = useMemo(() => {
        let tiles = [];
        Object.keys(stats).forEach(key => {
            if (key !== 'dateJoined' && key !== '__typename') {
                tiles.push(<SingleStat
                    key={key}
                    id={key}
                    stats={stats}/>);
            }
        });
        return tiles;
    }, [stats]);

    return (
        <div
            className="user-info-stats"
        >
            {statsTiles}
        </div>
    );
};

export default UserInfoStats;
