import React, {useMemo, useRef, useState} from "react";
import uuid from 'uuid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faEnvelope, faHomeAlt, faTimes} from "@fortawesome/pro-light-svg-icons";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {infoPageData} from '../data/infoPageText';
import workplace from '../assets/images/infoPage/workplace.jpeg'
import siteCover from '../assets/images/infoPage/site-cover-2.jpeg'
import footerCover from '../assets/images/infoPage/footer-cover3.jpeg'

// import {InfoSlider} from "./info/InfoSlider";
import {Requirement} from "./info/Requirement";
import {FeatureCard} from "./info/FeatureCard";
import {ApplicationForm} from "./info/ApplicationForm";

import '../assets/styles/pages/infoPage/infoPage.scss';
import {InfoSlider} from "./info/InfoSlider";


export const Info = () => {
    const [menuOpened, setMenuState] = useState(false);

    const {
        howTo,
        reviews,
        features,
        siteCover: {
            heading,
            subHeading,
            formTitle
        },
        requirements
    } = useMemo(() => infoPageData, []);

    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50);

    const homeRef = useRef(null);
    const howToRef = useRef(null);
    const prosRef = useRef(null);
    const reviewsRef = useRef(null);

    const toggleMenu = () => setMenuState(!menuOpened);

    const renderInfo = (target, Comp) => {
        return target.map((item) => {
            return <Comp
                key={uuid.v4()}
                data={item}
            />
        })
    };

    const howTos = useMemo(() => {
        return renderInfo(howTo, FeatureCard);
    }, [howTo]);


    const opRequirements1 = useMemo(() => {
        return renderInfo(requirements.slice(0, requirements.length / 2), Requirement)
    }, [requirements]);

    const opRequirements2 = useMemo(() => {
        return renderInfo(requirements.slice(requirements.length / 2, 6), Requirement)
    }, [requirements]);

    const ourFeatures = useMemo(() => {
        return renderInfo(features, FeatureCard);
    }, [features]);


    const faQuestions = [
        {
            title: 'З чого складається робота?',
            text: `Тобі необхідно буде робити мітки на фотографіях, виділяючи на ній фрагмент та обираючи зі списку назву бренду. Ці дані використовуються для навчання нейронної мережі (штучного інтелекту). Після реєстрації тобі буде наданий доступ до інструкції, де ти зможеш отримати необхідну інформацію про роботу.`
        },
        {
            title: 'Як відбувається оплата?',
            text: 'Оплата здійснюється раз на тиждень по п’ятницях шляхом переказу коштів на вашу банківську картку.',
        },
        {
            title: 'Скільки часу проходить з моменту реєстрації до старту роботи?',
            text: 'Проміжок часу від моменту реєстрації до старту роботи складає менше 24 годин. Тобі не потрібно витрачати час на співбесіди, очікування зворотнього зв’язку та стажування. Починай заробляти вже зараз!',
        },
        {
            title: 'Я зареєструвався. Які мої наступні кроки?',
            text: 'Після реєстрації супервайзер надсилає тобі посилання, за яким ти зможеш ознайомитись з інструкцією по роботі, з системою та переліком брендів для роботи. Після ознайомлення ти отримаєш дані для входу до особистого кабінету, в якому можна знайти доступні завдання та можещ одразу розпочинати роботу!',
        },
        {
            title: 'Чи буде у мене наставник або керівник?',
            text: 'Відсутність керівників. У тебе буде наставник-супервайзер, який зможе проконсультувати по всім питанням та допомогти у разі виникнення труднощів.',
        },
    ];

    const faq = useMemo(() => {
        return faQuestions.map(question => {
            const {title, text} = question;
            return <div
                key={uuid.v4()}
                className='con'>
                <h1 className='sub-section-heading'>
                    {title}
                </h1>
                <p className='site-text'>
                    {text}
                </p>
            </div>
        });
    }, [faQuestions]);


    return <div className='info-page-wrap'>
        <div className='sticky-container'>
            <header className='site-navbar'>
                <div className='container'>

                    <div className='logo-menu-wrapper'>
                        <div
                            onClick={_ => scrollToRef(homeRef)}
                            className='site-logo no-select'>
                            OUTFORZ
                        </div>
                        <div className='site-menu no-select'>
                            <ul>
                                <li
                                    onClick={() => scrollToRef(homeRef)}>
                                    <FontAwesomeIcon
                                        icon={faHomeAlt}/>
                                </li>
                                <li
                                    onClick={() => scrollToRef(howToRef)}>
                                    Як стати
                                </li>
                                <li
                                    onClick={() => scrollToRef(prosRef)}>
                                    Переваги
                                </li>
                                <li
                                    onClick={() => scrollToRef(reviewsRef)}>
                                    Питання
                                </li>
                            </ul>
                        </div>
                        <div
                            className='mobile-menu-button'
                            onClick={toggleMenu}
                        >
                            <FontAwesomeIcon
                                size={'2x'}
                                icon={menuOpened ? faTimes : faBars}
                                color={'#000'}
                            />
                        </div>
                    </div>
                </div>
            </header>
        </div>

        {menuOpened && <div className='mobile-menu'>
            <ul>
                <li
                    onClick={() => {
                        toggleMenu();
                        scrollToRef(homeRef);
                    }}>
                    <FontAwesomeIcon icon={faHomeAlt}/>
                </li>
                <li
                    onClick={() => {
                        toggleMenu();
                        scrollToRef(howToRef);
                    }}>
                    Як стати
                </li>
                <li
                    onClick={() => {
                        toggleMenu();
                        scrollToRef(prosRef)
                    }}>
                    Переваги
                </li>
                <li
                    onClick={() => {
                        toggleMenu();
                        scrollToRef(reviewsRef)
                    }}>
                    Поширені запитання
                </li>
            </ul>
        </div>}

        <div
            ref={homeRef}
            className='site-cover'
            style={{
                background: `linear-gradient(
                            rgba(0, 0, 0, 0.7),
                            rgba(0, 0, 0, 0.7)), 
                            url(${siteCover})`,
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className='container'>
                <div
                    className='site-cover-content'
                >
                    <div className='site-cover-left'>
                        <h1 className='section-heading'>{heading}</h1>
                        <p>{subHeading}</p>
                    </div>
                    <div className='site-cover-right'>
                        <h1 className='application-form-title'>{formTitle}</h1>
                        <ApplicationForm/>
                    </div>
                </div>
            </div>

        </div>

        <section
            className='site-section'
            ref={howToRef}
            style={{
                position: 'relative',
            }}
        >
            <h1 className='section-heading'>Як стати оператором?</h1>
            <div className='how-it-works container'>
                <div className='flex-wrap-container'>
                    {howTos}
                </div>

                <div
                    className='requirements with-background'
                    style={{
                        backgroundImage: `url(${workplace})`,
                    }}
                >
                    <div className='darken-back'></div>

                    <h1 className='sub-section-heading light-text'>Вимоги до кандидата:</h1>

                    <div className='requirements-wrapper'>
                        <div className='requirements-sub-wrapper light-text'>
                            {opRequirements1}
                        </div>

                        <div className='requirements-sub-wrapper light-text'>
                            {opRequirements2}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            className='site-section'
            ref={prosRef}>
            <div className='container'>

                <h1 className='section-heading'>Переваги співпрацювання з нами</h1>
                <div className='small-container'>
                    <div className='flex-wrap-container'>
                        {ourFeatures}
                    </div>
                </div>

                <div className='main-cons small-container'>
                    <div className='con'>
                        <h1 className='sub-section-heading'>
                            Твій заробіток
                        </h1>
                        <p className='site-text'>
                            У нас фіксована оплата, яка нараховується за кількість кліків та складає від 500 грн на
                            тиждень.
                        </p>
                    </div>

                    <div className='con'>
                        <h1 className='sub-section-heading'>
                            Все просто
                        </h1>
                        <p className='site-text'>
                            Що необхідно для старту? Наявність домашнього ПК або ноуту, доступ до Internet та мінімальні
                            навички роботи з комп'ютером. <br/>Ти ще студент, знаходишся у декреті чи просто прихильник
                            віддаленої роботи – обмежень немає.
                        </p>
                    </div>

                    <div className='con'>
                        <h1 className='sub-section-heading'>
                            Працюй де зручно і коли завгодно
                        </h1>
                        <p className='site-text'>
                            Дистанційна робота та гнучкий графік. Ти маєш можливість обирати зручний час для роботи, не
                            витрачати щоденно час на дорогу та самостійно контролювати своє завантаження. Кілька годин
                            на день або більше - вибір за тобою! Запитай про можливість працювати у свого супервайзера.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section
            className='site-section'
            ref={reviewsRef}>
            <div className='reviews-container container'>
                <h1 className='section-heading'>Поширені запитання</h1>
                <div className='flex-wrap-container small-container main-cons'>
                    <InfoSlider sliderItems={faq}/>
                </div>
            </div>
        </section>

        {/*<section*/}
        {/*    className='site-section'*/}
        {/*    ref={specialsRef}>*/}

        {/*<div className='special-offers-container container'>*/}
        {/*    <h1 className='section-heading'>*/}
        {/*        Спеціальні пропозиції*/}
        {/*    </h1>*/}

        {/*    <div className='flex-wrap-container'>*/}
        {/*        <div className='special-offer-wrapper'>*/}
        {/*            <div className='special-offer'>*/}
        {/*                <h1 className='sub-section-heading'>*/}
        {/*                    Приведи друга - отримай бонус*/}
        {/*                </h1>*/}
        {/*                <p>Приведи друга та отримай 150грн.</p>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className='special-offer-wrapper'>*/}
        {/*            <div className='special-offer'>*/}
        {/*                <h1 className='sub-section-heading'>*/}
        {/*                    +50 гривень до тижневого заробітку*/}
        {/*                </h1>*/}
        {/*                <p>ТОП 5 найкращих операторів – отримують 50 грн до свого заробітку.*/}
        {/*                    Умови акції:*/}
        {/*                    Бонус отримують оператори які отримали найбільший дохід за тиждень. </p>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*</section>*/}

        <footer>
            <div
                className='footer-background with-background'
                style={{
                    backgroundImage: `url(${footerCover})`,
                }}
            >
                <div className='darken-back'></div>
                <div className='container footer-container'>
                    <div className='footer-contacts'>
                        <h1 className='sub-section-heading'>Email:</h1>
                        <div className='contacts-wrapper'>
                            <div className='single-contact'>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    color={'#000'}
                                    size='1x'
                                />
                                <h1>hr@outforz.com</h1>
                            </div>
                        </div>
                    </div>
                    <div className='footer-form'>
                        <h1 className='application-form-title light-text'>{infoPageData.siteCover.formTitle}</h1>
                        <ApplicationForm/>
                    </div>
                </div>
                <h2 className='footer-year light-text'><span>&#169; </span>{new Date().getFullYear()} Outforz</h2>
            </div>
        </footer>
    </div>
};
