import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getRandomColor} from "../../../utils/getRandColor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpandArrows} from "@fortawesome/pro-light-svg-icons";

const AnnotationRectangle = (
    {
        id,
        handleDelete,
        item,
        handleSubMenu,
        setShowObjectTitle,
        showAnnotationMenu,
        item: {x1, y1, x2, y2},
        settings: {
            width: imgWidth,
            height: imgHeight
        },
        refScrollTop,
        resizeAnnotation
    }
) => {
    const [borderCol, setBorderCol] = useState('#fff');
    const [computedStyle, setComputedStyle] = useState({});
    const [objectRectangleMove, setObjectRectangleMove] = useState(false);



    useMemo(() => {
        setBorderCol(getRandomColor);
    }, []);


    const getContextMenu = e => {
        // console.log('RIGHT CLICKED ON', item);
        e.preventDefault();
        const {clientX: _contextX, clientY: _contextY} = e;
        const position = {
            _contextX: (_contextX - 156),
            _contextY: (_contextY - 80 + refScrollTop)
        };
        handleSubMenu(item, borderCol, position);
    };

    useEffect(() => {
        if (x1 * imgWidth) {
            let style = {
                left: (x1 > x2 ? x2 : x1) * imgWidth,
                top: (y1 > y2 ? y2 : y1) * imgHeight,
                width: Math.abs(x2 - x1) * imgWidth,
                height: Math.abs(y2 - y1) * imgHeight,
            };
            setComputedStyle(style);
        }
    }, [x1, x2, y1, y2, imgWidth, imgHeight]);

    const handleClick = useCallback(e => {
        if (e.shiftKey) {
            handleDelete(id);
            setShowObjectTitle(null);
        }
    }, [id, handleDelete, setShowObjectTitle]);

    return (
        <div
            className='object-rectangle'
            onContextMenu={getContextMenu}

            onMouseEnter={e => {
                // if (e.altKey)
                if (item && item.name)
                    setObjectRectangleMove(true);
                    setShowObjectTitle({...item, borderCol})
            }}

            onMouseLeave={() => {
                setObjectRectangleMove(false);
                setShowObjectTitle(null)
            }}

            onClick={handleClick}

            style={{
                ...computedStyle,
                borderColor: borderCol,
                boxShadow: `inset 0 0 34px -9px ${borderCol}`,
            }}>

            {objectRectangleMove&&<span className="objectRectangleMove" style={{color: borderCol}} onMouseDown={()=>resizeAnnotation(item)}><FontAwesomeIcon icon={faExpandArrows} size={'2x'} /></span>}
        </div>
    );
};

export default AnnotationRectangle;
