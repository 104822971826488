import React from "react";
import {Redirect, Route} from "react-router";
import useGlobal from "../../store";

function RolesRoute({forSupervisor, forOperator, ...rest}) {
    const [{userInfo: {role}}] = useGlobal();

    let component = null;

    switch (role) {
        case 'OPERATOR':
            component = forOperator;
            break;
        case 'SUPERVISOR':
            component = forSupervisor;
            break;
        default:
            component = null
    }

    if (!component) {
        return <Redirect to='/login'/>;
    }

    return <Route {...rest} component={component}/>;
}

export default RolesRoute;