import React, {useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faPlus} from "@fortawesome/pro-light-svg-icons";
import {v4} from 'uuid';
import useGlobal from "../store";

import calcMagnify from "../utils/magnifier/calcMagnify";

import PosArrow from "./magnifier/PosArrow";

const Magnifier = ({photo, mousePos, photoSize, magnifierSett, topScrolled}) => {
    const [globalState] = useGlobal();
    const {activeGradient, gradients} = globalState;

    const [topBot, setTopBot] = useState('bottom');
    const [leftRight, setLeftRight] = useState('right');

    const [position, setPosition] = useState({
        top: 0,
        right: 0
    });

    const {accentColor} = useMemo(() => {
        return gradients[activeGradient]
    }, [activeGradient, gradients]);

    const {width, height} = photoSize;

    const {resSize, resScale} = magnifierSett;

    const scaleStyles = useMemo(() => {
        if (!photo) return;
        const {newX, newY} = calcMagnify(resSize, photoSize, mousePos, resScale, topScrolled);
        return {
            width: resSize,
            height: resSize,
            backgroundImage: `url(${photo})`,
            backgroundPosition: `${newX}px ${newY}px`,
            backgroundSize: `${width * resScale}px ${height * resScale}px`,
            boxShadow: `0 0 24px -9px ${accentColor}`,
            [topBot]: 0,
            [leftRight]: 0,
        }
    }, [photo, mousePos, accentColor, topBot, leftRight, resSize, topScrolled, resScale]);

    const arrowsData = [
        {
            align: 'top',
            icon: faChevronUp
        },
        {
            align: 'right',
            icon: faChevronRight
        },
        {
            align: 'bottom',
            icon: faChevronDown
        },
        {
            align: 'left',
            icon: faChevronLeft
        }];

    const renderedArrows = useMemo(() =>
        arrowsData.map(arrow => {
                const {align, icon} = arrow;
                return <PosArrow
                    icon={icon}
                    key={v4()}
                    placement={align}
                    setPlacement={{
                        setTopBot,
                        setLeftRight
                    }}
                    sideSize={resSize}
                />
            }
        ), [resSize]);


    return <div
        className={'custom-magnifier'}
        style={scaleStyles}
    >
        <div className='custom-magnifier-center'>
            <FontAwesomeIcon icon={faPlus}/>
        </div>
        <div className='custom-magnifier-arrows-container'>
            {renderedArrows}
        </div>
    </div>
};

export default Magnifier;
