import React, {memo, useEffect} from "react";
import useGlobal from "../../../../store";

const ObjectItem = ({selectedObject, item, handleObjectSelect, showHoveredImage}) => {

    // console.log('ddddd',selectedObject);

    const [{gradients, activeGradient}] = useGlobal();

    return <li
        onMouseOver={() => {

            if (selectedObject && selectedObject.image){
                showHoveredImage(selectedObject.image);
            } else {
                showHoveredImage(item.image);
            }
        }}
        // onMouseLeave={() => {
        //     if (!selectedObject)
        //         showHoveredImage();
        // }}
        style={{
            background: selectedObject && selectedObject.id === item.id
                ? gradients[activeGradient].accentColor
                : gradients[activeGradient].secondaryContainer
        }}
        className={selectedObject && selectedObject.id === item.id
            ? 'selected-object'
            : ''
        }
        onClick={() => handleObjectSelect(item)}
    >
        {item.name}
    </li>
};

export default ObjectItem;
