import {useState} from "react";

import formatAnnotations from "../components/drawAnnotation/formatAnnotations";

const useAnnotationManager = () => {
    const [annotations, setAnnotations] = useState([]);
    const [removedAnnotations, setRemovedAnnotations] = useState([]);

    const manager = {
        add: (newAnnotation) => {
            setAnnotations([...annotations, newAnnotation]);
        },
        undo: () => {
            if (!annotations.length) return;
            const lastAddedAnnotation = annotations[annotations.length - 1];
            const filteredAnnotations = annotations.splice(-1, 1);
            setAnnotations(filteredAnnotations);
            setRemovedAnnotations([...removedAnnotations, lastAddedAnnotation]);
        },
        redo: (cb) => {
            if (!annotations.length) return;

            const lastRemovedAnnotation = removedAnnotations[removedAnnotations.length - 1];
            const filteredRemoved = removedAnnotations.splice(-1, 1);

            setAnnotations([...annotations, lastRemovedAnnotation]);
            setRemovedAnnotations(filteredRemoved);

            cb && cb(lastRemovedAnnotation);
        },
        delete: id => {
            const filteredAnnotations = annotations.filter(item => item.id !== id);
            setAnnotations(filteredAnnotations);
        },

        resize: (oldId, newInfo) => {
            let annotationsNew=annotations.map(item =>
                item.id === oldId.id
                    ? {...item, ...newInfo}
                    : item
            );

            setAnnotations(annotationsNew);
        },

        edit: (newInfo, oldId) => {
            const {id, object: {name}, additionalInfo} = newInfo;

            const {price, salePrice, quantity} = JSON.parse(additionalInfo);
            const lastAnnotation = {
                name,
                id,
                price,
                salePrice,
                quantity
            };

            if (oldId) {
                setAnnotations(annotations.map(item =>
                    item.id === oldId.id
                        ? {...item, ...lastAnnotation}
                        : item
                ))
            } else {
                const lastAdded = annotations[annotations.length - 1];
                setAnnotations(annotations.slice(0, -1).concat({...lastAdded, ...lastAnnotation}))
            }
        },
        hydrate: (photo) => {
            if (photo && photo.id) {
                setAnnotations(formatAnnotations(photo.annotationSet));
            }
        }
    };

    return {
        manager,
        annotations,
        removedAnnotations
    };
};

export default useAnnotationManager;
