import React, {useEffect, useMemo, useRef, useState} from "react";
import useGlobal from "../../../store";

const ObjectTitle = ({hoveredObject, imgSize, topScrolled}) => {
    const {name, price, salePrice, quantity, x1, x2, y1, y2, borderCol} = hoveredObject;
    const [{gradients, activeGradient}] = useGlobal();
    const {primaryContainer, secondaryContainer, textColor} = useMemo(() => {
        return gradients[activeGradient]
    }, [gradients, activeGradient]);

    const {price: _price, salePrice: _salePrice} = useMemo(() => ({
        ...(price && {price: price.value}),
        ...(salePrice && {salePrice: salePrice.value}),
    }), [price, salePrice]);


    // object Height - important for bottom object rendering
    const [objectHeight, setObjectHeight] = useState(null);
    const containerRef = useRef(null);
    useEffect( () => {
        if(containerRef.current){
            setObjectHeight(containerRef.current.offsetHeight);
        }
    }, [containerRef]);


    const {l, t} = useMemo(() => {
        const {width, height} = imgSize;
        const minY = (y1 > y2 ? y2 : y1) * height;
        let l=(x1 > x2 ? x1 : x2) * width + 80

        // let t=(minY < topScrolled ? minY * (topScrolled / height) : minY - topScrolled);
        let t=(minY-topScrolled);

        if ((objectHeight+t)>(height-topScrolled)){ // taking to consideration objectHeight
            t=(height-topScrolled)-objectHeight
        }

        return {l: l, t: t}
    }, [x1, y1, imgSize, topScrolled, objectHeight]);


    return (
        <div ref={containerRef}
            className='object-title'
            style={{
                top: t,
                left: l,
                boxShadow: `0 0 24px -9px ${borderCol}, 0 0 24px -9px #000`,
                border: `1px solid ${borderCol}`,
                backgroundColor: primaryContainer,
            }}
        >
            <h1
                style={{
                    backgroundColor: secondaryContainer,
                    color: textColor,
                }}
            >{name}</h1>
            {(_price || _salePrice) &&
            <div className='object-title-prices'>
                {_price && <h2
                    style={{
                        backgroundColor: secondaryContainer,
                        color: textColor
                    }}
                >Ціна: <span>{_price}</span></h2>}
                {_salePrice && <h2
                    style={{
                        backgroundColor: secondaryContainer,
                        color: textColor
                    }}
                >Акційна ціна: <span>{_salePrice}</span></h2>}
            </div>}
            <div className="d-flex object-title-prices">
                {quantity?.value && (<h2
                    style={{
                        backgroundColor: secondaryContainer,
                        color: textColor,
                        width: '100%'
                    }}
                >Кількість: <span>{quantity?.value}</span></h2>)}
            </div>
        </div>
    )
};
export default ObjectTitle;
