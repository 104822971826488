import {colorScheme} from "../../data/ColorSchemes";

const generalState = {
    modalState: false,
    messageStatus: {
        type: 'null',
        body: null
    },
    generalStats: {},
    ...colorScheme,
    mousePos: {
        x: 200,
        y: 200
    },
    imgScale: 4
};

const setGeneralStats = (store, data) => {
    // console.log("SET GENERAL STATS",data);
    store.setState({
        generalStats: data
    });
};

const toggleModal = store => {
    const modalState = !store.state.modalState;
    store.setState({modalState});
};

const showMessage = (store, data) => {
    const {type, body} = data;
    const messageStatus = type !== 'reset'
        ? {type, body} : {type: null, body: null};

    store.setState({messageStatus});
};

const setBackGradient = (store, id) => {
    store.setState({
        activeGradient: id ? id : Object.keys(store.state.gradients)[0]
    })
};

const resetGeneral = store => {
    store.setState({
        ...generalState
    });

};

const setMousePosition = (store, mousePos) => {
    store.setState({
        mousePos
    })
}

const generalActions = {
    showMessage,
    toggleModal,
    resetGeneral,
    setGeneralStats,
    setBackGradient,
    setMousePosition
};

export {generalActions, generalState};
