import classes from 'classnames';
import {useHistory} from "react-router";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowLeft} from "@fortawesome/pro-light-svg-icons";
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import useGlobal from "../../../store";

import {path} from "../../../utils/api/apiQueries";

import Loading from "../../../components/Loading";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const StartButton = () => {
        const [pathName, setPathName] = useState(null);
        const [globalState, globalActions] = useGlobal();
        const {id, active, ticketContents} = globalState.ticketInfo;
        const {gradients, activeGradient, takenBySupervisor, tickets, userInfo} = globalState;
        const {companyId, role, id: userId} = globalState.userInfo;
        const {setTicketContent, resetTicket, showMessage} = globalActions;

        const [getTicket, {data, loading, refetch}] = useLazyQuery(path.query.getFirstTicket);

        const [finishTicket, {data: isFinished, loading: closeLoading}] = useMutation(path.mutation.finishTicket);

        const {push, goBack, location: {pathname}} = useHistory();


        useEffect(() => {
            setPathName(pathname);
        }, [pathname, setPathName]);

        useEffect(() => {
            if (role === 'SUPERVISOR')
                if (pathName === '/main' || pathName === '/main/tickets-list') {
                    setTicketContent()
                }
        }, [pathName, role, setTicketContent]);


    const getTicketAgain = () => {

           console.log('i2',userInfo);

            if (userInfo.ticketsInProgress && userInfo.ticketsInProgress.length>=1){
                showMessage({
                    type: 'warning',
                    body: 'Ви не можете взяти тікет так як у Вас уже є тікет в роботі в іншій Компанії'
                });
                return;
            }

            if (!companyId) {
                showMessage({
                    type: 'warning',
                    body: 'Оберіть компанію'
                });
                return;
            }

            if (takenBySupervisor) {
                showMessage({
                    type: 'warning',
                    body: 'Ви вже маєте тікет в роботі, завершіть його спочатку'
                });
                return;
            }


            if (!data && companyId) {
                console.log("getTicket")
                getTicket({
                    variables: {
                        status: 'open',
                        company: companyId
                    },
                    fetchPolicy: 'no-cache'
                });
            }

            if (data && companyId) {
                console.log("refetch")
                refetch({
                    status: 'open',
                    company: companyId
                }).catch()
            }
        };

        const finishActiveTicket = useCallback(() => {

            if (!validOrAnnotated(ticketContents)) {
                showMessage({
                    type: 'warning',
                    body: 'Всі фото повинні мати аннотації, або позначено недійсним'
                });
                return;
            }

            confirmAlert({
                title: 'Завершити тікет',
                message: 'Чи дійсно Ви бажаєте завершити тікет?',
                buttons: [
                    {
                        label: 'Так',
                        onClick: () => {

                            finishTicket({
                                variables: {
                                    id
                                }
                            }).catch(() => {
                                showMessage({
                                    type: 'failure',
                                    body: "Тікет не завершено. Щось пішло не так!"
                                });
                            });

                        }
                    },
                    {
                        label: 'Ні',
                        onClick: () => {}
                    }
                ]
            });

        }, [finishTicket, id, ticketContents]);

        const validOrAnnotated = arr => {
            return arr.every(content => {
                return (content.valid && content.annotationSet.edges.length) || !content.valid
            })
        };

        useEffect(() => {
            if (!isFinished) return;
            if (isFinished.closeTicketMutation.ok) {
                showMessage({
                    type: 'success',
                    body: 'Тікет успішно завершено!'
                });
                resetTicket();

                if (role === "SUPERVISOR") {
                    if (tickets.length === 1) {
                        push('/main');
                        return
                    }
                    goBack();
                }
                // goBack();
            }
        }, [isFinished, showMessage, push, resetTicket, role]);

        useEffect(() => {
            if (!data) return;
            if (!data.allTickets.edges.length) {
                showMessage({
                    type: 'warning',
                    body: 'Немає незавершених/доступних тікетів!'
                });
                return;
            }
            if (role === "SUPERVISOR") {
                push({
                    pathname: './main/tickets-list',
                    search: userId
                });
            }
            setTicketContent(data.allTickets.edges[0].node);
        }, [data, setTicketContent, showMessage]);

        const buttonClass = classes(
            'start-button no-select',
            {active}
        );

        const buttonAction = useMemo(() => {

                if (pathName === '/main/tickets-list') {
                    return goBack;
                }
                if (active && (pathName === '/main/annotation' || pathName === '/annotation')) {
                    return goBack;
                }
                if (active) {
                    return finishActiveTicket;
                }
                return getTicketAgain
            },
            [ticketContents, data, active, pathName, getTicketAgain, finishActiveTicket, goBack]);


        const style = useMemo(() => {
            if (role === 'SUPERVISOR') {
                if (pathName === '/main')
                    return {
                        backgroundColor: '#16a085',
                    };
                if (pathName === '/main/tickets-list')
                    return {
                        backgroundColor: '#f1c40f',
                    };
            }

            if (pathName === '/main/annotation' || pathName === '/annotation') {
                return {
                    backgroundColor: '#f1c40f',
                }
            }
            return {
                backgroundColor: gradients[activeGradient].accentColor,
            }
        }, [pathName, activeGradient]);

        const buttonState = useMemo(() => {
            if (pathName !== '/main/annotation' && pathName !== '/main/tickets-list') {
                return <h1>{`${active ? "Завершити" : "Взяти"} тікет`}</h1>
            }

            return <>
                <FontAwesomeIcon icon={faLongArrowLeft} color={'#fff'}/>
                <h1>
                    Назад
                </h1>
            </>
        }, [pathName, active]);

        return (
            <div
                className={buttonClass}
                onClick={buttonAction}
                style={{
                    ...style
                }}
            >
                {(loading || closeLoading) && <Loading/>}

                {(!loading && !closeLoading) && buttonState}
            </div>
        );
    }
;
