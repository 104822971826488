import React from "react";
import {v4} from 'uuid'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faCheck} from "@fortawesome/pro-light-svg-icons";

export const Requirement = ({data}) => {
    return <div className='requirement' key={v4()}>
        <FontAwesomeIcon
            icon={faCheck}
            color={'#fff'}
            size='1x'
        />
        <h1>{data.text}</h1>
    </div>
};