import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlicorn, faExclamationCircle, faShieldCheck, faTimes} from "@fortawesome/pro-light-svg-icons";

const InfoMessage = ({messageStatus: {type, body}, decline}) => {
        let faIcon = null;

        switch (type) {
            case 'success': {
                faIcon = {
                    icon: faShieldCheck,
                    color: '#2ED573'
                };
                break;
            }
            case 'successSmall':{
                faIcon = {
                    icon: faShieldCheck,
                    color: '#2ED573'
                };
              break;
            }
            case 'warning': {
                faIcon = {
                    icon: faExclamationCircle,
                    color: '#f9ca24'
                };
                break;
            }
            case 'error': {
                faIcon = {
                    icon: faTimes,
                    color: '#c0392b'
                };
                break;
            }
            default:
                faIcon = faAlicorn;
                break;
        }

        const messStyles = {
            boxShadow: `0 0 25px -9px ${faIcon.color}`,
            borderColor: faIcon.color
        };

        return <div
            style={messStyles}
            className={'info-message'}
            onClick={decline}
        >
            <FontAwesomeIcon
                icon={faIcon.icon}
                color={faIcon.color}
                size={'3x'}
            />
            {body && <h1>{body}</h1>}
        </div>
    }
;

export default InfoMessage;