import {useMemo, useState} from "react";
import {detectLeftButton} from "../utils/annotations/detectLeftClick";
import uuid from "uuid";


const useHandleUserEvents = (
    settings,
    refScrollTop,
    annotationManager,
    drawStateActions,
    resizeMode
) => {
    const {width: imgWidth, height: imgHeight} = settings;
    const {
        drawActions: {
            setToggleContext,
            setShowAnnotationMenu,
            handleDelete,
            setMousePosition
        },
        drawState: {
            toggleContext,
            showAnnotationMenu,
            annotations
        }
    } = drawStateActions;

    const [coords, setCoords] = useState({});
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [tempRect, setTempRect] = useState([]);

    const calcCoords = e => {
        const {type, clientX, clientY} = e;
        return {
            [type === 'mousedown' ? 'x1' : 'x2']: (clientX - 116) / imgWidth,
            [type === 'mousedown' ? 'y1' : 'y2']: (clientY - 40 + refScrollTop) / imgHeight,
        };
    };

    const mouseDown = e => {
        const isLeftButton = detectLeftButton(e);

        if (isLeftButton && !e.shiftKey && !showAnnotationMenu) {
            setIsMouseDown(true);
            setCoords(calcCoords(e));
            // setToggleContext(null);
        }

        if (e.target.classList.contains('draw-overlay')) {
            toggleContext && setToggleContext(null);
            showAnnotationMenu && handleDelete(annotations[annotations.length - 1].id);
            showAnnotationMenu && setShowAnnotationMenu(null);
        }

        // if (toggleContext) {
        //     setToggleContext(null);
        // }
    };

    let rectId = useMemo(() => uuid.v4(), []);

    const mouseMove = e => {
        setMousePosition({x: e.clientX, y: e.clientY});
        if (isMouseDown && !showAnnotationMenu){
            let calculatedCoords=calcCoords(e);

            if (resizeMode.state==false){
                setTempRect([{rectId, ...coords, ...calculatedCoords}]);
            } else {
                annotationManager.resize(resizeMode.annotation, {...resizeMode.annotation, ...calculatedCoords});
            }
        }
    };

    const reset = () => {
        setCoords({});
        setTempRect([]);
        setIsMouseDown(false);
    };

    const mouseUp = e => {
        if (!isMouseDown) return;
        reset();
        return {id: uuid.v4(), ...coords, ...calcCoords(e)};
    }

    return {
        userEvents: {
            mouseUp,
            mouseDown,
            mouseMove,
            reset
        },
        userEventsData: {
            tempRect
        }
    }
};

export default useHandleUserEvents;
