import React, {useState, useEffect} from 'react';

import {ImagePlaceholder} from "../components/placeholder/ImagePlaceholder";

export const useImagePlaceholder = count => {
    const [placeholders, setPlaceholders] = useState([]);

    useEffect(() => {
        const placeholdersArr = [];
        const setImagePlaceholder = () => {
            for (let i = 0; i < count; i++) {
                placeholdersArr.push(
                    <ImagePlaceholder key={i}/>
                )
            }
            setPlaceholders(placeholdersArr);
        };
        setImagePlaceholder();
    }, [count]);
    return [placeholders]
};