const calcMagnify = (targetSize, imgSize, mouseCoords, scale, topScrolled) => {
    let sideSize = targetSize;
    let halfSideSize = sideSize / 2;

    const {x, y} = mouseCoords;
    const {width, height} = imgSize;

    let scaleX = (x - 116) * scale;
    let scaleY = (y - 40 + topScrolled) * scale;

    let newX = halfSideSize - scaleX;
    let newY = halfSideSize - scaleY;

    const n = scaleX + halfSideSize < width * scale
    const m = scaleX - halfSideSize > 0

    if (n && !m) {
        newX = 0
    }

    if (!n && m) {
        newX = sideSize - width * scale
    }

    const k = scaleY + halfSideSize < height * scale
    const p = scaleY - halfSideSize > 0

    if (k && !p) {
        newY = 0
    }
    if (!k && p) {
        newY = sideSize - height * scale
    }

    return {
        newX,
        newY
    }
};
export default calcMagnify;