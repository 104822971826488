import React, {useCallback, useMemo} from "react";

const ContextAnnotation = props => {
    const {
        imgSize: {width, height},
        photoToContext: {
            id,
            color,
            position: {
                _contextX,
                _contextY
            }
        },
        actions: {
            handleDelete,
            handleChange,
            handleCancelContext,
        },
    } = props;


    let topPosition=_contextY;
    if ((_contextY+170)>height){
        topPosition=height-170;
    }

    const style = useMemo(() => {
        return {
            left: width - _contextX < 200 ? _contextX - 50 : _contextX,
            top: topPosition,
            backgroundColor: color,
        };
    }, [_contextX, _contextY]);


    const handleRemove = useCallback(() => {
        handleDelete(id)
    }, [id, handleDelete])


    const handleEdit = useCallback(() => handleChange(id), [id, handleChange])

    return (
        <div
            style={style}
            className='rect-context-menu'
        >
            <div
                className='context-menu-button'
                onClick={handleEdit}
            >
                <h1>Edit</h1>
            </div>

            <div
                className='context-menu-button'
                onClick={handleRemove}
            >
                <h1>Delete</h1>
            </div>

            <div
                className='context-menu-button'
                onClick={handleCancelContext}
            >
                <h1>Cancel</h1>
            </div>



        </div>
    );
};
export default ContextAnnotation;
