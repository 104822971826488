export const colorScheme = {
    gradients: {
        a3ae29699f1242ee85f373efc5b9f9df: {
            backGroundCol: 'linear-gradient(to left, #0F2027, #203A43, #2C5364)',
            primaryContainer: '#fff',
            secondaryContainer: '#f4f4f4',
            accentColor: 'rgba(44, 83, 100, 1)',
            textColor: '#000',
        },
        ac14db43c6c04c208efbe1e0e4db7b13: {
            backGroundCol: 'linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%)',
            primaryContainer: '#fff',
            secondaryContainer: '#f4f4f4',
            accentColor: 'rgba(237, 110, 160, 1)',
            textColor: '#000',
        },
        a4af293ac53f4b02a523fa3bc4ca302b: {
            backGroundCol: 'linear-gradient(to top, #09203f 0%, #537895 100%)',
            primaryContainer: '#fff',
            secondaryContainer: '#f4f4f4',
            accentColor: 'rgba(83, 120, 149, 1)',
            textColor: '#000',
        },
        e5cd66b232824b9eaefb8b858784ad83: {
            backGroundCol: 'linear-gradient(to top, #30cfd0 0%, #330867 100%)',
            primaryContainer: '#fff',
            secondaryContainer: '#f4f4f4',
            accentColor: 'rgba(51, 8, 103, 1)',
            textColor: '#000',
        },
        ac885241e54544dd95aa8398f7087168: {
            // linearGradient: 'linear-gradient(to right, #6a11cb 0%, #2575fc 100%)',
            backGroundCol: '#000',
            primaryContainer: '#1F1F1F',
            secondaryContainer: '#000',
            accentColor: 'rgba(106, 17, 203, 1)',
            textColor: '#fff',
        },
    },
    activeGradient: 'a3ae29699f1242ee85f373efc5b9f9df'
};