import React from 'react';
import classNames from 'classnames';

import UserInfoHeader from "./userInfo/UserInfoHeader";

import useGlobal from "../../../store";
import useColorMode from "../../../hooks/useColorMode";

const UserInfo = () => {
    const [globalState, globalActions] = useGlobal();
    const {
        modalState,
        userInfo,
        gradients,
        activeGradient,
    } = globalState;
    const {toggleModal} = globalActions;
    const className = classNames('container', 'user-info-wrapper');

    const styles = useColorMode();

    return (
      <div
        className={className}
        style={{
          ...styles,
          boxShadow: `0 0 24px -9px ${gradients[activeGradient].accentColor}`,
        }}
        onClick={toggleModal}
      >
        {!modalState && <UserInfoHeader userInfo={userInfo} />}
      </div>
    );
};
export default UserInfo;